import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import NavbarWidget from './widgets/NavbarWidget';
import ServicesWidget from './widgets/ServicesWidget';
import CommandsWidget from './widgets/CommandsWidget';
import FooterWidget from './widgets/FooterWidget';
import CommandNavbarWidget from './widgets/CommandNavbarWidget';
import './Common.css'
import ReactHtmlParser from 'react-html-parser';
const utf8 = require('utf8');

const CommandPage = ( props ) => {
	const page 				= "Linux Command";
	const pageTitle 		= "Linux Command | Linux.Tips";
	const user				= props.user;
	const urlslug 			= props.match.params.urlslug;
	const isAuthenticated 	= user.user ? true : false;

	const [commandsList, 	setCommandsList] 	= useState(false);
	const [command, 		setCommand] 		= useState(false);
	const [isCommand, 		setIsCommand] 		= useState(false);
	const [dataLoaded, 		setDataLoaded] 		= useState(false);

	useEffect(() => {
		// getCommandsData for widget
    	const getCommandsData = async () => {
			const result = await fetch('/command/allPublished');
			const commandDocuments = await result.json();
			if (commandDocuments) setCommandsList(commandDocuments);
			else window.location.replace('/');
			console.log(commandDocuments);
			setDataLoaded(true);
		}

		const isCommand = async () => {
    		const response = await fetch(`/command/commandname/${urlslug}`);
			const commandDocument = await response.json();
			if (commandDocument) {
				if (!(commandDocument.isPublished)) window.location.replace('/');
				setCommand(commandDocument);
				setIsCommand(true);
				getCommandsData();
			} else {
				setIsCommand(false);
			}
    	}

		if (props.dataLoaded) {
	    	isCommand();
	    }
    }, [props, urlslug]);

	// If not a command, return empty for the component
	if (!isCommand)
    	return <></>;

	return (
		<React.Fragment>
		{!(dataLoaded && props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{pageTitle}</title>
				<NavbarWidget page={page} user={user} />
				<CommandNavbarWidget commandsList={commandsList} flag={1} />
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-8">
							<div className="my-3 p-3 bg-white rounded shadow-sm">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item"><Link to={`/commands/list`}>Linux Commands</Link></li>
									<li className="breadcrumb-item active">{command.title}</li>
								</ol>
								<div className="card-body">
									<h5 className="border-bottom border-gray pb-2 mb-0 text-secondary">Linux {command.title} Command</h5>
									<div className="card-text">
										{!(isAuthenticated && user.user.privileges === "super-admin") ? "" :
											<Link to={{pathname: `/commands/edit/${command.urlslug}`, info: {command}}} >
												<button type="button" className="btn btn-outline-primary btn-sm mt-3 mr-1 mb-3">Edit</button>
											</Link>
										}
										<div className="mt-2">
											Written under <Link to='/commands/list'>Linux commands</Link>
											{!(command) ? '' : ReactHtmlParser(utf8.decode(command.body))}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-sm-4">
							<ServicesWidget />
				        </div>
					</div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default CommandPage;