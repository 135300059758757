import React, { useEffect } 	from 'react';

/*
MAIN REASON to create this is because of /:category/:article-title!


All the pages and who has access to them.

-- visitor --
/
/contact
/about
/error
/commands/list/
/commands/:name/
/categories/:name
/pages/:page_num
/:category/:article-title
/user/id/:id

-- registered user --
/article/create
/article/edit/:article-name
/commands/create/
/commands/edit/:command-name
/user/profile

-- super user --
/messages
/users
/category/create

*/

const UnknownUrlPage = (props) => {
	// constant urls without any additional parameters
    const constParams = [	"", "contact", "contact/", "about", "about/", "error", "error/", 
    						"messages", "messages/", "users", "users/", 
    						"commands/list", "commands/list/", "category/create", "category/create/", 
    						"commands/create", "commands/create/", "article/create/", "article/create", 
    						"user/profile/", "user/profile" ];

    // url with params
	// [/commands/:name/     /categories/:name     /pages/:page_num]
	const semiConstParams = ["commands", "categories", "page"];

	useEffect(() => {
		const isArticle = async (categoryname) => {
    		const response = await fetch(`/category/categoryname/${categoryname}`);
			const categoryDocuments = await response.json();
			if (categoryDocuments === null) {
				window.location.replace('/error');
			}
    	}

    	const params = props.match.params[0].split('/');

    	// case 1: any of the const url params
    	if (!(constParams.includes(props.match.params[0]))) {
    		//console.log("in heere");
            // case 2: any of the semi const url params
    		if (params.length === 1) {
                //console.log("in heere");
                window.location.replace('/error');
            } else if (params.length === 2) {
                if (params[1] === "") window.location.replace('/error');
    			// case 3 is it an article /:category/:article-title
    			if (!(semiConstParams.includes(params[0]))) {
    				isArticle(params[0]);
    			}
			} else if (params.length === 3) {
    			// case 4: /article/edit/:article-name or /commands/edit/:command-name
    			if (!((params[0] === "article" && params[1] === "edit") || 
    				(params[0] === "commands" && params[1] === "edit") || 
    				(params[0] === "user" && params[1] === "id")))
    				window.location.replace('/error');
			} else if (params.length === 4) {
    			// case 5: /article/edit/:article-name/ or /commands/edit/:command-name/
    			if (params[3] === "") {
    				if (!((params[0] === "article" && params[1] === "edit") || 
	    				(params[0] === "commands" && params[1] === "edit") || 
	    				(params[0] === "user" && params[1] === "id")))
	    				window.location.replace('/error');
    			} else {
    				window.location.replace('/error');
    			}
			} else {
				window.location.replace('/error');
			}
    	}
    }, [props, constParams, semiConstParams]);

    return <></>;
};

export default UnknownUrlPage;