import React, { useState, useEffect } 	from 	'react';
import { Link }             			from    "react-router-dom";
import NavbarWidget 					from 	'./widgets/NavbarWidget';
import ServicesWidget 					from 	'./widgets/ServicesWidget';
import CategoryWidget 					from 	'./widgets/CategoryWidget';
import FooterWidget 					from 	'./widgets/FooterWidget';
import CategoryNavbarWidget 			from 	'./widgets/CategoryNavbarWidget';
import 											'./Common.css'

const CommandsListPage = ( props ) => {
	const page 				= "Linux Commands";
	const pageTitle 		= "Linux Commands | Linux.Tips";
	const user				= props.user;

	const [commands,		setCommands] 		= useState('');
	const [categoryList, 	setCategoryList] 	= useState('');
	const [dataLoaded, 		setDataLoaded] 		= useState(false);

	useEffect(() => {
		const getCategoryData = async () => {
			const response = await fetch('/category/list');
			const categoryDocuments = await response.json();
			if (categoryDocuments) setCategoryList(categoryDocuments);
			else window.location.replace('/error');
			setDataLoaded(true);
		}

		const commandsData = async () => {
			const result = await fetch('/command/allPublished');
			const commandDocuments = await result.json();
			if (commandDocuments) setCommands(commandDocuments);
			else window.location.replace('/error');
			getCategoryData();
		}
        
        if (props.dataLoaded) commandsData();
    }, [props]);

	return (
		<React.Fragment>
		{!(dataLoaded & props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{pageTitle}</title>
				<NavbarWidget page={page} user={user} />
				<CategoryNavbarWidget categoryList={categoryList} />
				<div id="wrap">
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-8">
								<div className="my-3 p-3 bg-white rounded shadow-sm">
									<ol className="breadcrumb">
										<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
										<li className="breadcrumb-item active">Linux Commands</li>
									</ol>
									<table className="table table-bordered">
										<thead>
											<tr>
												<th scope="col">Commands</th>
												<th scope="col">Description</th>
											</tr>
										</thead>
										<tbody>
											{!(commands) ? '' : commands.map((command, key) => (									
												<tr key={command._id}>
													<td key={command._id}>
														<Link key={command._id+1} to={`/commands/${command.urlslug}`}>{command.title}</Link><br></br>
													</td>
													<td>{command.summary}</td>
												</tr>
											))}
										</tbody>
									</table>

								</div>
							</div>
							<div className="col-sm-4">
								<ServicesWidget />
								<CategoryWidget categoryList={categoryList} />
						    </div>    
						</div>
					</div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default CommandsListPage;