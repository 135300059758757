import React, { useState, useEffect } 	from 	'react';
import { Link } 						from 	'react-router-dom';
import FooterWidget 					from 	'./widgets/FooterWidget';
import NavbarWidget 					from 	'./widgets/NavbarWidget';
import 											'./Common.css';

const UserPage = (props) => {
	const page 		= "User";
	const userid 	= props.match.params.userid;
	const user 		= props.user;

	const [userArticles, 	setUserArticles] 	= useState('');
	const [dataLoaded, 	 	setDataLoaded] 		= useState(false);
	const [searchedUser, 	setSearchedUser] 	= useState('');

	useEffect(() => {
		const getArticlesByUser = async () => {
			const result = await fetch(`/article/userid/${userid}`);
			const articleInfo = await result.json();
			if (articleInfo) setUserArticles(articleInfo);
			else window.location.replace('/error');
			setDataLoaded(true);
		}

		const getUserFromId = async () => {
			const result = await fetch(`/user/id/${userid}`);
			const userInfo = await result.json();
			if (userInfo._id) setSearchedUser(userInfo);
			else window.location.replace('/error');
		}

		if (props.dataLoaded) {
			getUserFromId();
			getArticlesByUser();
		}
	}, [props, user, userid]);

	return (
		<React.Fragment>
		{!(dataLoaded && props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{searchedUser.displayName + " | Linux.Tips"}</title>
				<NavbarWidget page={page} user={user} />
				<div className="container-fluid mt-5">
			        <div className="row">
			            <div className="col-sm-12">
			            	<div className="my-3 p-3 bg-white rounded shadow-sm">
				            	<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item">User</li>
									<li className="breadcrumb-item active">{searchedUser.displayName}</li>
								</ol>
		                    	<h5 className="border-bottom border-gray pb-2 mb-0 text-secondary">{searchedUser.displayName}</h5>
		                    	<div className="card-body mb-2">
			                    	{searchedUser.about}
								</div>
		                    	<h5 className="border-bottom border-gray pb-2 mb-0 text-secondary">Articles</h5>
		                    	<div className="card-body mb-2">
		                    		<ul className="list-group list-group-flush">
										{!(userArticles) ? '' : userArticles.map((article, key) => (
											<li key={article._id} className="list-group-item">
												<Link key={article._id} to={`/${article.primarycategory[1]}/${article.urlslug}`}>
									                {article.title}
									            </Link>
											</li>
										))}
									</ul>
								</div>
		                    </div>
			            </div>
			        </div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default UserPage;