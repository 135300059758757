import React, { useState, useEffect } 	from 	'react';
import { Link } 						from 'react-router-dom';
import NavbarWidget 					from 	'./widgets/NavbarWidget';
import FooterWidget 					from 	'./widgets/FooterWidget';
import ServicesWidget 					from 	'./widgets/ServicesWidget';
import 											'./Common.css'

const ContactPage = ( props ) => {
	const page 				= "Contact";
	const pageTitle 		= "Contact | Linux.Tips";
	const user				= props.user;

	// React Hooks
	const [dataLoaded, 		setdataLoaded] 		= useState(false);
	const [contactName, 	setContactName] 	= useState('');
	const [contactEmail, 	setContactEmail] 	= useState('');
	const [contactPhone, 	setContactPhone] 	= useState('');
	const [contactMessage, 	setContactMessage] 	= useState('');

	// Input Validation
	props.location.state = {
		errors: {
			contactName: (user.user) ? '' : 'Required',
			contactEmail: (user.user) ? '' : 'Required',
			contactPhone: '',
			contactMessage: 'Required'
		}
	};

	useEffect(() => {
		if (props.dataLoaded) {
			if (user.user) {
				setContactName(user.user.displayName);
				setContactEmail(user.user.email);
			}
			setdataLoaded(true);
		}
	}, [props.dataLoaded, user.user]);

	// POST
	const sendMessage = async () => {
		const result = await fetch('contact/addMessage/', {
			method: 'post',
			body: JSON.stringify({ 
				contactName: 	contactName,
				contactEmail: 	contactEmail,
				contactPhone: 	contactPhone,
				contactMessage: contactMessage 
			}),
			headers: {
				'Content-Type': 'application/json',
			}
		});

		await result.json();
		if (!(user.user)) {
			setContactName('');
			setContactEmail('');
		}
		setContactPhone('');
		setContactMessage('');
	}

	// Input Validation of the write article form
	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
        let errors = props.location.state.errors;

        switch (name) {
			case 'contactName': 
				setContactName(event.target.value);
				errors.contactName = (value.length < 2 || value.length > 15) ? 'Name required and must be at least 2 characters and at most 15 characters!' : '';
				break;
			case 'contactEmail': 
				setContactEmail(event.target.value);
				errors.contactEmail = value.length < 12 ? 'Must be a valid email!' : '';
				break;
			case 'contactPhone': 
				setContactPhone(event.target.value);
				errors.contactPhone = value.length !== 10 ? 'Number should be only 10 number characters!' : '';
				break;
			case 'contactMessage': 
				setContactMessage(event.target.value);
				errors.contactMessage = (value.length < 10 || value.length > 500) ? 'Comment must be at least 10 characters and at most 500 characters!' : '';
				break;
			default:
				break;
        }

		props.location.state.errors = errors;
	}

	const validateForm = (errors) => {
		let valid = true;
		Object.values(errors).forEach(
			(val) => val.length > 0 && (valid = false)
		);
		return valid;
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		if (validateForm(props.location.state.errors)) {
			console.info('Valid Form.');
			sendMessage();
		} else {
			console.error('Invalid Form.');
		}
	}

	return (
		<React.Fragment>
		{!(dataLoaded && props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{pageTitle}</title>
				<NavbarWidget page={page} user={user} />
				<div className="container-fluid mt-5">
			        <div className="row">
			            <div className="col-sm-8">
			            	<div className="my-3 p-3 bg-white rounded shadow-sm">
			            		<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item active">Contact</li>
								</ol>
		                    	<div className="card-body">
			                    	<div className="form-group">
				                    	<div className="mb-4">
				                    		Want to get in touch with us? Fill out the form below to send us a message and we will try to get back to you within 24 hours!
				                    	</div>
			                        
			                        	{(user.user) ?
				                            <input type="text" className="form-control mb-2" name="contactName" placeholder="Name" value={contactName} onChange={handleChange} disabled /> 
				                            :
											<input type="text" className="form-control mb-2" name="contactName" placeholder="Name" value="" onChange={handleChange} />
										}
										{(props.location.state.errors.contactName.length > 0) ?
											<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.contactName}</small>
											: ""
										}
			                        
			                            {(user.user) ?
			                            	<input type="text" className="form-control mb-2" name="contactEmail" placeholder="Email" value={contactEmail} onChange={handleChange} disabled /> :
			                            	<input type="text" className="form-control mb-2" name="contactEmail" placeholder="Email" value={contactEmail} onChange={handleChange} />
			                            }
			                            {(props.location.state.errors.contactEmail.length > 0) ?
											<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.contactEmail}</small>
											: ""
										}

			                            <input type="text" className="form-control mb-2" name="contactPhone" placeholder="Phone (234-567-8910)"  value={contactPhone} onChange={handleChange} />
			                            {(props.location.state.errors.contactPhone.length > 0) ?
											<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.contactPhone}</small>
											: ""
										}

										<textarea className="form-control mb-2" id="postArea" name="contactMessage" placeholder="Message" rows="15" value={contactMessage} onChange={handleChange} ></textarea>
			                            {(props.location.state.errors.contactMessage.length > 0) ?
											<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.contactMessage}</small>
											: ""
										}

										<p>
				                            <button type="button" className="btn btn-success btn-default mt-2" onClick={handleSubmit}>Submit</button>
				                        </p>
			                        </div>
								</div>
		                    </div>
			            </div>
			            <div className="col-sm-4">
							<ServicesWidget />
						</div>
			        </div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default ContactPage;