import React, { useState, useEffect } 	from 	'react';
import { Link } 						from 	'react-router-dom';
import NavbarWidget 					from 	'./widgets/NavbarWidget';
import FooterWidget 					from 	'./widgets/FooterWidget';
import 											'./Common.css';

const UsersPage = ( props ) => {
	const page 				= "All Users";
	const user				= props.user;

	const [users, 		setUsers] 		= useState('');
	const [dataLoaded, 	setDataLoaded] 	= useState(false);

	useEffect(() => {
		const getAllUsers = async () => {
			const response = await fetch('/user/all');
			const userDocuments = await response.json();
			setUsers(userDocuments);
			setDataLoaded(true);
		}
        
        // make sure user data is loaded
		if (props.dataLoaded) {
			// if user is logged in
			if (user.user) {
				// if user not super admin, get em out of here
				if (user.user.privileges !== "super-admin") {
					window.location.replace('/error');
				} else {
					// get the users
			        getAllUsers();
				}
			} else {
				// if user not logged in, get em out of here
				window.location.replace('/error');
			}
		}
    }, [props, user]);

	return (
		<React.Fragment>
		{!(dataLoaded && props.dataLoaded) ? "" :
			<React.Fragment>
				<title>All Articles | Linux.Tips</title>
				<NavbarWidget page={page} user={user} />
				<div className="container-fluid mt-6">
			        <div className="row">
			            <div className="col-sm-12">
			            	<div className="my-3 p-3 bg-white rounded shadow-sm">
				            	<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item active">Users</li>
								</ol>
								<ul className="list-group list-group-flush">
									{!(users) ? '' : users.map((user, key) => (
										<div key={user._id}>
											<li className="list-group-item" key={user._id}>
												{(user.privileges === "super-admin") ? 
													<a key={user._id} href="/" className="badge badge-success mr-4">Super</a> :
													<a key={user._id} href="/articles" className="badge badge-primary mr-4">Regular</a> 
												}
												<a key={user._id+1} href={'/user/id/' + user._id}>{user.displayName}</a>
											</li>
										</div>
									))}
								</ul>
		                    </div>
			            </div>
			        </div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default UsersPage;