import React, { useState, useEffect } 	from 	'react';
import { Link } 						from 	'react-router-dom';
import NavbarWidget 					from 	'./widgets/NavbarWidget';
import ArticleBody 						from	'./widgets/ArticleBody';
import ArticleComments 					from 	'./widgets/ArticleComments';
import ServicesWidget 					from 	'./widgets/ServicesWidget';
import CategoryWidget 					from 	'./widgets/CategoryWidget';
import CommandsWidget 					from 	'./widgets/CommandsWidget';
import FooterWidget 					from 	'./widgets/FooterWidget';
import RecommendedListWidget 			from 	'./widgets/RecommendedListWidget';
import CategoryNavbarWidget 			from 	'./widgets/CategoryNavbarWidget';
import 											'./Common.css'

const ArticlePage = ( props ) => {
	const page 				= "Article Page";
	const urlslug 			= props.match.params.urlslug;
	const categoryname 		= props.match.params.categoryname;
	const user				= props.user;
	const isAuthenticated 	= user.user ? true : false;

	// GET user
	const [isArticle, 			setIsArticle] 			= useState(false);
	const [article, 			setArticle] 			= useState('');
	const [dataLoaded, 			setDataLoaded] 			= useState(false);
	const [recommendedArticles, setRecommendedArticles] = useState('');	
	const [commandsList, 		setCommandsList] 		= useState(false);
	const [categoryList, 		setCategoryList] 		= useState('');

    useEffect(() => {
        const getRecommendedArticles = async () => {
			const response = await fetch(`/article/getRecommendedArticles/`);
			const articles = await response.json();
			if (articles) setRecommendedArticles(articles);
			else window.location.replace('/error');
			setDataLoaded(true);
		}

		// getCommandsData for widget
    	const getCommandsData = async () => {
			const result = await fetch('/command/allPublished');
			const commandDocuments = await result.json();
			if (commandDocuments) setCommandsList(commandDocuments);
			else window.location.replace('/');
			getRecommendedArticles();
		}

		const getCategoryData = async () => {
			const response = await fetch('/category/list');
			const categoryDocuments = await response.json();
			if (categoryDocuments) setCategoryList(categoryDocuments);
			else window.location.replace('/error');
			getCommandsData();
		}

		const getArticleData = async () => {
			const response = await fetch(`/article/urlslug/${urlslug}`);
			const articleDocument = await response.json();
			if (articleDocument) {
				setArticle(articleDocument);
				if (!(articleDocument.isPublished)) window.location.replace('/');
				getCategoryData();
			} else {
				window.location.replace('/error');
			}
		}

		const isArticle = async () => {
    		const response = await fetch(`/category/categoryname/${categoryname}`);
			const categoryDocuments = await response.json();
			if (categoryDocuments) {
				setIsArticle(true);
				getArticleData();
			} else {
				setIsArticle(false);
			}
    	}

    	if (props.dataLoaded) isArticle();
    }, [props, urlslug, categoryname]);

    if (!isArticle)
    	return <></>;

	return (
		<React.Fragment>
		{!(dataLoaded && props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{!(article) ? '' : article.title + " | Linux.Tips"}</title>
				<NavbarWidget page={page} user={user} />
				<CategoryNavbarWidget categoryList={categoryList} />
				<div className="container-fluid">
			        <div className="row">
			            <div className="col-sm-8">
			            	<div className="my-3 p-3 bg-white rounded shadow-sm">
			            		<ol className="breadcrumb mb-0">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item"><Link to={`/categories/${article.primarycategory[1]}`}>{article.primarycategory[0]}</Link></li>
									<li className="breadcrumb-item active">{article.title}</li>
								</ol>

						        <div className="card-body pt-2">
							        {!(isAuthenticated && user.user.privileges === "super-admin") ? "" :
						            	<Link to={{pathname: `/article/edit/${article.urlslug}`, info: {article}}} >
											<button type="button" className="btn btn-outline-primary btn-sm mr-1 mt-2">Edit</button>
										</Link>
									}
									<ArticleBody article={article} />
								</div>
			                    <ArticleComments articlePrimaryCategory={article.primarycategory[1]} articleUrlslug={article.urlslug} articleTitle={article.title} setArticle={setArticle} match={props.match} />
		                    </div>
			            </div>
			            <div className="col-sm-4">
							<RecommendedListWidget recommendedArticles={recommendedArticles} />
			            	<CommandsWidget commandsList={commandsList} />
							<ServicesWidget />
					    </div>
			        </div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default ArticlePage;


