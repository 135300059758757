import React 				from 	'react';
import { Link } 			from 	'react-router-dom';
import NavbarWidget 		from 	'./widgets/NavbarWidget';
import ServicesWidget 		from 	'./widgets/ServicesWidget';
import FooterWidget 		from 	'./widgets/FooterWidget';
import 								'./Common.css';

const ErrorPage = (props) => {
	const page 				= "404 Error";
	const pageTitle 		= "404 Error | Linux.Tips";
	const user 				= props.user;

	return (
		<React.Fragment>
		{!(props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{pageTitle}</title>
				<NavbarWidget page={page} user={user} />
				<div className="container-fluid mt-6">
			        <div className="row">
			            <div className="col-sm-8">
			            	<div className="my-3 p-3 bg-white rounded shadow-sm">
		                    	<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item active">404 Error</li>
								</ol>
		                    	<div className="card-body">
		                    		You may have been redirected to this 404 Error page for many reasons including:
									<ul className="mt-3">
										<li>Server error.</li>
										<li>Page you are trying to access does not exist anymore or never existed.</li>
										<li>You do not have proper permissions to view this page.</li>
									</ul>
								</div>
		                    </div>
			            </div>
			            <div className="col-sm-4">
							<ServicesWidget />
						</div>
			        </div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default ErrorPage;