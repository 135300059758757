import React 		from 'react';
import { Link } 	from    "react-router-dom";

const CommandsWidget = ({ commandsList }) => (
    <div className="my-3 p-3 bg-white rounded shadow-sm">
        <h4 className="display-5 border-bottom border-gray pb-2 mb-0 text-secondary">Linux Commands</h4>
        <div className="mt-2">
            {!(commandsList) ? '' : commandsList.map((command, key) => (
                <Link className="badge badge-primary ml-2 mr-2 mt-2 mb-2" to={`/commands/${command.urlslug}`} key={key}>
	                {command.title}
	            </Link>
            ))}
        </div>
    </div>
);

export default CommandsWidget;