import React, { useState } from 'react';
import { Navbar, Nav, Button, Form, FormControl, NavDropdown } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './NavbarWidget.css'

const NavbarWidget = ({ page, user }) => {
	const isHomeActive 				= page === "Home" 					? "active" : "";
	const isWriteArticleActive 		= page === "Write Article" 			? "active" : "";
	// const isContactActive 			= page === "Contact" 				? "active" : "";
	const isProfileActive 			= page === "Profile" 				? "active" : "";
	const isAllUsersActive 			= page === "All Users" 				? "active" : "";
	const isMessagesActive 			= page === "Messages" 				? "active" : "";
	const isCreateCategoryActive 	= page === "Create Category" 		? "active" : "";
	const isCreateCommandsActive 	= page === "Create Linux Commands" 	? "active" : "";
	// const isAboutActive 			= page === "About" 					? "active" : "";

	const [search, setSearch] 		= useState('');
	
	// check if user is authenticated
	var isAuthenticated 			= user.user ? true : false;

	// Link to google search
	const searchArticle = async () => {
		window.location.replace('https://www.google.com/search?q=site:linux.tips%20' + search.toString());
	};

	// Enter key pressed in search input box
	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			if (event.target.value.length > 0)
				searchArticle();
		}
    }

	// Search btn click in navbar
    const handleClick = (event) => {
		if (search.length > 0)
			searchArticle();
    }

	return (
		<React.Fragment>
			<Navbar expand="lg" fixed="top" className="navbar-dark bg-dark" >
				<Navbar.Brand href="/" className="lead">Linux.Tips</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav" className="mr-auto mt-2 mt-lg-0">
					<Nav className="mr-auto">
						<Nav.Link href="/"  						className={isHomeActive} 				>Home 						</Nav.Link>
						{isAuthenticated ? "" :
							<>
								<Nav.Link href={process.env.NODE_ENV === 'development' ? 
												"http://localhost:8000/user/auth/google" : 
												"https://linux.tips/user/auth/google"}						>Google Sign In/Up 			</Nav.Link>
							</>
						}
						{!isAuthenticated ? "" : 
							<>
								<Nav.Link href="/user/profile" 			className={isProfileActive}			>Profile 					</Nav.Link>
								<NavDropdown title="Create" id="basic-nav-dropdown">
									<NavDropdown.Item href="/article/create" className={isWriteArticleActive}		>Article 			</NavDropdown.Item>
									<NavDropdown.Item href="/commands/create/" className={isCreateCommandsActive} 	>Linux Commands 	</NavDropdown.Item>
									{!(user.user.privileges === "super-admin") ? "" : 
										<NavDropdown.Item href="/category/create/"  className={isCreateCategoryActive}	>Category  		</NavDropdown.Item> }

								</NavDropdown>
							</>
						}
						{!(isAuthenticated && user.user.privileges === "super-admin") ? "" : 
							<>
								<Nav.Link href="/messages/" 		className={isMessagesActive}			>Messages 					</Nav.Link>
								<Nav.Link href="/users/" 			className={isAllUsersActive} 			>Users 						</Nav.Link>
							</>
						}
						{!isAuthenticated ? "" :
							<>
								<Nav.Link href={process.env.NODE_ENV === 'development' ? 
												"http://localhost:8000/user/logout/" :  
												"https://linux.tips/user/logout"}							>Logout 					</Nav.Link>
							</>
						}				
					</Nav>
					<Form inline className="my-2 my-lg-0">
						<FormControl type="text" placeholder="Search" className="mr-sm-2" value={search} onChange={(event) => setSearch(event.target.value)} onKeyPress={handleKeyPress} />
						<Button variant="success" className="my-2 my-sm-0" onClick={handleClick}>Search</Button>
					</Form>
				</Navbar.Collapse>
			</Navbar>
		</React.Fragment>
	);
}
export default NavbarWidget;
