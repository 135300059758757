import React            from    'react';
import { Link }         from    'react-router-dom';
import { convertDate }  from    '../Util.js'

const ArticlesList = ({ articlesList }) => (
    <React.Fragment>
    {!(articlesList) ? '' : articlesList.map((article, key) => (
        <div className="card-body border-bottom border-gray" key={key}>
            <Link to={`/${article.primarycategory[1]}/${article.urlslug}`}>
                <h6 className="card-title lead">{article.title}</h6>
            </Link>
            <small>
                {!(article) ? '' : 
                    (Object.keys(article.categories).map((item, idx) => (idx === Object.keys(article.categories).length - 1) ? 
                        <Link to={`/categories/${article.categories[item]}`} key={idx} className="badge badge-primary">#{item}</Link> : 
                        <Link to={`/categories/${article.categories[item]}`} key={idx} className="badge badge-primary mr-1">#{item}</Link>))}
            </small>
            <small className="text-muted ml-1">
                {!(article) ? '' : <Link to={`/user/id/${article.userid}`} className="badge badge-success">{article.username}</Link>} <span className="badge badge-dark">{convertDate(article.date.split("T")[0])}</span> <span className="badge badge-dark">{article.commentCount} Comments</span>
            </small>

            <br></br>
        </div>
    ))}
    </React.Fragment>
);

export default ArticlesList;