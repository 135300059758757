import React, { useState, useEffect } 	from 	'react';
import { Link }             			from    "react-router-dom";
import NavbarWidget 					from 	'./widgets/NavbarWidget';
import FooterWidget 					from 	'./widgets/FooterWidget';
import 											'./Common.css'

const ContactMessagesPage = ( props ) => {
	const page 		= "Messages";
	const user 		= props.user;

	const [messages, 	setMessages] 	= useState('');
	const [dataLoaded, 	setDataLoaded] 	= useState(false);

	useEffect(() => {
		const getMessages = async () => {
			const result = await fetch('/contact/messages');
			const body = await result.json();
			setMessages(body);
			setDataLoaded(true);
		}

		// make sure user data is loaded
		if (props.dataLoaded) {
			// if user is logged in
			if (user.user) {
				// if user not super admin, get em out of here
				if (user.user.privileges !== "super-admin") {
					window.location.replace('/error');
				} else {
					// get the messages
					getMessages();
				}
			} else {
				// if user not logged in, get em out of here
				window.location.replace('/error');
			}
		}
	}, [props, user]);

	return (
		<React.Fragment>
		{!(dataLoaded && props.dataLoaded) ? "" :
			<React.Fragment>
				<title>Messages | Linux.Tips</title>
				<NavbarWidget page={page} user={user} />
				<div className="container-fluid mt-6">
			        <div className="row">
			            <div className="col-sm-12">
			            	<div className="my-3 p-3 bg-white rounded shadow-sm">
			            		<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item active">Messages</li>
								</ol>
								<ul className="list-group list-group-flush">
									{!(messages) ? '' : messages.map((message, key) => (
										<div key={key}>
											<li className="list-group-item" key={key}>
												From: {message.name}
												<br></br>
												Email: {message.email}
												<br></br>
												Phone: {message.phone}
												<br></br>
												Message: {message.message}
											</li>
										</div>
									))}
								</ul>
		                    </div>
			            </div>
			        </div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default ContactMessagesPage;