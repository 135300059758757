import React 		from 'react';
import { Link } 	from "react-router-dom";

const CategoryNavbarWidget = ({ categoryList }) => (
    <div className="nav-scroller bg-white box-shadow mt-6">
		<nav className="nav nav-underline">
			<Link className="nav-link active" to="/">Tags</Link>
			{!(categoryList) ? '' : categoryList.map((category, key) => (
                <Link className="nav-link" to={`/categories/${category.url}`} key={key}>
	                {category.title}
	                <span className="badge badge-pill badge-dark align-text-bottom ml-1">{category.articleid.length}</span>
	            </Link>
            ))}
		</nav>
    </div>
);

export default CategoryNavbarWidget;