import React from 'react';
import { Link } from 'react-router-dom';

const ServicesWidget = () => (
    <div className="my-3 p-3 bg-white rounded shadow-sm">
		<h4 className="display-5 border-bottom border-gray pb-2 mb-0 text-secondary">Services</h4>
		<ul className="list-group list-group-flush">
			<Link to='/commands/list/'><li className="list-group-item border-bottom border-gray" >Linux Commands</li></Link>
			<Link to='/contact'><li className="list-group-item border-bottom border-gray">Have a question? Contact Us</li></Link>
			<Link to='/about'><li className="list-group-item border-bottom border-gray">About</li></Link>
		</ul>
    </div>
);

export default ServicesWidget;