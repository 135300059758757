import React, { useState, useEffect } from 'react';
import { convertDate } from '../Util.js'

const ArticleComments = ({ articleTitle, articleUrlslug, articlePrimaryCategory, setArticle }) => {
    const [articleComments,     setArticleComments]     = useState();
    const [comment,             setComment]             = useState('');
    const [name,                setName]                = useState('');
    const [email,               setEmail]               = useState('');

    const [editCommentId,       setEditCommentId]       = useState('');
    const [editComment,         setEditComment]         = useState('');

    // GET user
    const [user,        setUser]         = useState('');
    const [dataLoaded,  setDataLoaded]   = useState(false);
    const [errors,      setErrors]       = useState('');

    useEffect(() => {
        const getArticleComments = async (urlslug) => {
            const response = await fetch(`/comment/published/${articleUrlslug}`);
            const commentDocument = await response.json();
            if (commentDocument) {
                setArticleComments(commentDocument);
                setDataLoaded(true);
            } else {
                window.location.replace('/error');
            }
        }

        const userData = async () => {
            const result = await fetch('/user/auth/isAuthenticated');
            const userInfo = await result.json();
            if (userInfo) {
                setUser(userInfo);
                var errors = {
                    comment: 'Comment must be at least 5 characters and at most 250 characters!',
                    name: (userInfo.user) ? '' : 'Required',
                    editcomment: '',
                    email: (userInfo.user) ? '' : 'Required'
                };
                setErrors(errors);
                getArticleComments();
            }
        }

        userData();
    }, [articleUrlslug, editComment]);

    // POST
    const addComment = async () => {
        var userId = (user.user) ? user.user._id : -1;
        var userName = (user.user) ? user.user.displayName : name;
        var userEmail = (user.user) ? user.user.email : email;
        
        // insert comment in Comment collection
        const result = await fetch('/comment/add/', {
            method: 'post',
            body: JSON.stringify({
                comment:                comment,
                userId:                 userId,
                username:               userName,
                email:                  userEmail,
                articleUrlslug:         articleUrlslug,
                articleTitle:           articleTitle,
                articlePrimaryCategory: articlePrimaryCategory
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });

        await result.json();

        // increment comment count in Article collection
        const resultTwo = await fetch('/article/incrementCommentCount/', {
            method: 'post',
            body: JSON.stringify({
                articleUrlslug:     articleUrlslug
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });

        let article = await resultTwo.json();
        setArticle(article);

        const response = await fetch(`/comment/published/${articleUrlslug}`);
        const commentDocument = await response.json();
        if (commentDocument) {
            setArticleComments(commentDocument);
        }
    }

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        
        switch (name) {
            case 'buttonClick':
                if (comment.length > 5) {
                    addComment();
                    errors.comment = '';
                }
                break;
            case 'name':
                setName(event.target.value);
                if (!(user.user))
                    errors.name = (value.length < 2 || value.length > 15) ? 'Name required and must be at least 2 characters and at most 15 characters!' : '';
                break;
            case 'email':
                setEmail(event.target.value);
                if (!(user.user))
                    errors.email = (value.length < 5 || value.length > 25) ? 'Email required!' : '';
                break;
            case 'comment': 
                setComment(event.target.value);
                errors.comment = (value.length < 5 || value.length > 250) ? 'Comment must be at least 5 characters and at most 250 characters!' : '';
                break;
            case 'updateComment':
                setEditComment(event.target.value);
                errors.editcomment = (value.length < 5 || value.length > 250) ? 'Comment must be at least 5 characters and at most 250 characters!' : '';
                break;
            default:
                break;
        }
    };

    const setEditMode = async (commentId, comment) => {
        setEditCommentId(commentId);
        setEditComment(comment);
    }

    const closeEditMode = async () => {
        setEditCommentId('');
        setEditComment('');
        errors.editcomment = '';
    }

    const updateComment = async () => {
        const result = await fetch('/comment/update/', {
            method: 'post',
            body: JSON.stringify({
                commentId:          editCommentId,
                comment:            editComment
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });

        await result.json();

        closeEditMode();
    }

    return ( 
        <React.Fragment>
        {!(dataLoaded) ? "" :
            <div className="card">
                <div className="card-header">
                    {!(articleComments) ? '' : articleComments.length} Responses
                </div>

                <div className="card-body">
                    <form>
                        <div className="form-row">
                            <div className="col">
                                {(user.user) ? "" :
                                    <>
                                    <input type="text" name="name" className="form-control mb-2" placeholder="Name" value={name} onChange={handleChange} />
                                    {(errors.name.length > 0) ?
                                        <small id="titleerror" className="form-text text-danger mb-2">{errors.name}</small>
                                        : <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
                                    }

                                    <input type="text" name="email" className="form-control mb-2" placeholder="Email" value={email} onChange={handleChange} />
                                    {(errors.email.length > 0) ?
                                        <small id="titleerror" className="form-text text-danger mb-2">{errors.email}</small>
                                        : <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
                                    }
                                    </>
                                }
                                <textarea className="form-control form-control-sm" name="comment" rows="3" placeholder="Response" value={comment} onChange={handleChange}></textarea>
                                {(errors.comment.length > 0) ?
                                    <small id="titleerror" className="form-text text-danger mb-2">{errors.comment}</small>
                                    : <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
                                }
                            </div>
                        </div>
                        <button type="button" name="buttonClick" className="btn btn-success d-block text-right mt-3" onClick={handleChange}>Write Response</button>
                    </form>

                    <div className="media my-4">
                        <div className="media-body">
                            {!(articleComments) ? '' : articleComments.map((comment, key) => (
                                <div className="comment card-body border-bottom border-gray" key={key}>
                                    {(editCommentId === comment._id) ? 
                                        <>
                                        <h6 className="card-title">{(comment.userId === "-1") ? comment.username : <a href={"/user/id/" + comment.userId}>{comment.username}</a>}</h6>
                                        <h6 className="card-subtitle mb-2 text-muted"><small className="text-muted">{convertDate(comment.date.split("T")[0])}</small></h6>
                                        <textarea className="form-control form-control-sm" name="updateComment" rows="3" value={editComment} onChange={handleChange}></textarea>
                                        {(errors.editcomment.length > 0) ?
                                            <small id="titleerror" className="form-text text-danger mb-2">{errors.editcomment}</small>
                                            : <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
                                        }
                                        <span className="badge badge-pill badge-primary mr-2 mt-2" onClick={() => updateComment()}>Update</span>
                                        <span className="badge badge-pill badge-dark mt-2" onClick={() => closeEditMode()}>Close</span>
                                        </>
                                        :
                                        <>
                                        {(user.user) && ((user.user.privileges === "super-admin") || (user.user && (comment.userId === user.user._id))) ?
                                            <div className="d-block text-right">
                                                <span className="badge badge-dark" onClick={() => setEditMode(comment._id, comment.comment)}>Edit</span>
                                            </div>
                                            :
                                            ""
                                        }
                                        <h6 className="card-title">{(comment.userId === "-1") ? comment.username : <a href={"/user/id/" + comment.userId}>{comment.username}</a>}</h6>
                                        <h6 className="card-subtitle mb-2 text-muted"><small className="text-muted">{convertDate(comment.date.split("T")[0])}</small></h6>
                                        <p className="card-text">{comment.comment}</p>
                                        </>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        }
        </React.Fragment>
    );
};

export default ArticleComments;
