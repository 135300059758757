import React                        from    'react';
import ReactHtmlParser              from    'react-html-parser';
import { Link }                     from    "react-router-dom";
import                                      '@fortawesome/fontawesome-free/css/brands.min.css';
import                                      '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import                                      './ArticleBody.css'
import { convertDate }              from    '../Util.js'
import { OverlayTrigger, Tooltip }  from    'react-bootstrap'

const utf8 = require('utf8');

const ArticleBody = ({ article }) => (
    <React.Fragment>
        <div className="d-block text-right">
            {(article.prevArticle.length) ?
                <Link to={`/${article.prevArticle[1]}/${article.prevArticle[2]}`}>
                    <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="Disabled tooltip">
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={<Tooltip id="button-tooltip" >{article.prevArticle[0].length > 40 ? article.prevArticle[0].substring(0, 40) + "..." : article.prevArticle[0]}</Tooltip>} >
                            <em className="mb-2 mr-2 mt-2">Previous</em>
                        </OverlayTrigger>
                    </span>
                </Link>
                : 
                ""
            }

            {(article.nextArticle.length) ? 
                <Link to={`/${article.nextArticle[1]}/${article.nextArticle[2]}`}>
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={<Tooltip id="button-tooltip" >{article.nextArticle[0].length > 40 ? article.nextArticle[0].substring(0, 40) + "..." : article.nextArticle[0]}</Tooltip>} >
                        <em className="mb-2 mr-2 mt-2">Next</em>
                    </OverlayTrigger>
                </Link>
                : 
                ""
            }
        </div>

        <h5 className="card-title mt-2">{!(article) ? '' : article.title}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{!(article) ? '' : article.summary}</h6>
        <div className="card-text my-3">
            <small className="text-muted">Published by {!(article) ? '' : <Link to={`/user/id/${article.userid}`}>{article.username}</Link>} on {!(article) ? '' : convertDate(article.date.split("T")[0])} with {!(article) ? '' : article.commentCount} Comment</small>
            <br></br>
            <small>
            Categories: {!(article) ? '' : 
                    (Object.keys(article.categories).map((item, idx) => (idx === Object.keys(article.categories).length - 1) ?
                        <Link to={`/categories/${article.categories[item]}`} key={idx}>{item}</Link> : 
                        <Link to={`/categories/${article.categories[item]}`} key={idx}>{item}, </Link>))}
            </small>
        </div>
        <div className="card-text my-4">
            {!(article) ? '' : ReactHtmlParser(utf8.decode(article.body))}
        </div>
        <div className="mt-5 mb-3">
            <h5>Share it!</h5>
            <a href={"https://www.facebook.com/sharer.php?u=" + window.location.href}><i className="fab fa-facebook fa-2x p-2 mr-2"></i></a>
            <a href={"https://twitter.com/intent/tweet?url=" + window.location.href}><i className="fab fa-twitter  fa-2x p-2 mr-2"></i></a>
            <a href={"https://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href}><i className="fab fa-linkedin fa-2x p-2 mr-2"></i></a>
        </div>
    </React.Fragment>
);

export default ArticleBody;