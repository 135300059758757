import React, { useState, useEffect } 	from 	'react';
import { Redirect, Link } 				from 	"react-router-dom";
import NavbarWidget 					from 	'./widgets/NavbarWidget';
import FooterWidget 					from 	'./widgets/FooterWidget';
import ReactHtmlParser              	from 	'react-html-parser';
import { convertDate }              	from 	'./Util.js';
import 											'./Common.css';

const utf8 = require('utf8');

const CreateCommandsPage = ( props ) => {
	const isEditCommandPage = (props.match.params.urlslug) ? true : false;
	const page 				= ((isEditCommandPage) ? "Edit" : "Create") + " Linux Commands";
	const pageTitle 		= ((isEditCommandPage) ? "Edit" : "Create") + " Linux Commands | Linux.Tips";
	const user				= props.user;
	const isAuthenticated 	= user.user ? true : false;

	// Hooks for user data and data loaded flag
	const [dataLoaded, 			setDataLoaded] 			= useState(false);

	// Hooks for command data
	const [command, 				setCommand] 				= useState('');
	const [commandDate, 			setCommandDate] 			= useState(!(isEditCommandPage) ? new Date().toISOString().split('T')[0] : '');
	const [commandTitle, 			setCommandTitle] 			= useState(!(isEditCommandPage) ? "This is the command title" : '');
	const [commandSummary,  		setCommandSummary]  		= useState(!(isEditCommandPage) ? "This is the command summary" : '');
	const [commandURLSlug,  		setCommandURLSlug]  		= useState(!(isEditCommandPage) ? "this-is-the-url-slug" : '');
	const [commandBody, 			setCommandBody] 			= useState(!(isEditCommandPage) ? "<p>This is a paragraph</p>" : '');

	useEffect(() => {
		// Input Validation for command
		props.location.state = {
			errors: {
				commandTitle: 			(isEditCommandPage) ? "" : 'Required',
				commandSummary: 		(isEditCommandPage) ? "" : 'Required',
				commandURLSlug: 		(isEditCommandPage) ? "" : 'Required',
				commandBody: 			(isEditCommandPage) ? "" : 'Required',
				commandDate: 			(isEditCommandPage) ? "" : 'Required'
			}
		};

		// If edit page, query server for the command data
		const getCommandData = async () => {
			const response = await fetch(`/command/commandname/${props.match.params.urlslug}`);
			const commandDocument = await response.json();
			if (commandDocument) {
				if (user.user.privileges !== "super-admin" && commandDocument.userid !== user.user._id) {
					window.location.replace('/error');
				}
				setCommand(JSON.parse(JSON.stringify(commandDocument)));
				setCommandTitle(commandDocument.title);
				setCommandSummary(commandDocument.summary);
				setCommandURLSlug(commandDocument.urlslug);
				setCommandBody(commandDocument.body);
				setCommandDate(commandDocument.date);
				setDataLoaded(true);
			} else {
				window.location.replace('/error');
			}
		}

		// make sure user data loaded
		if (props.dataLoaded) {
			// check if user is logged in, else get em out of here
			if (user.user) {
				// if edit command, getCommandData, else create new command
				if (isEditCommandPage) {
					getCommandData();
				} else {
					setDataLoaded(true);
				}
			} else {
				window.location.replace('/error');
			}
		}

	}, [props, user, isEditCommandPage]);

	// POST server request for adding the command to db
	const writeCommand = async (isCommandPublished, isCommandInReview) => {
		const requestURL = (isEditCommandPage) ? '/command/update/' : '/command/add/';
		const response = await fetch(requestURL, {
			method: 'post',
			body: JSON.stringify({ 
				commandId: 					(isEditCommandPage) ? command._id 		: "",
  				commandUserId: 				(isEditCommandPage) ? command.userid 	: user.user._id,
				commandUserName: 			(isEditCommandPage) ? command.username  : user.user.displayName,
				commandTitle: 				commandTitle,
				commandSummary: 			commandSummary,
				commandURLSlug : 			commandURLSlug,
				commandBody: 				utf8.encode(commandBody),
				isCommandPublished: 		isCommandPublished,
				commandDate: 				commandDate,
				isCommandInReview: 			isCommandInReview
			}),
			headers: {
				'Content-Type': 'application/json',
			}
		});

		let categoryObject = await response.json();
		console.log(categoryObject);

		// window.location.replace('/command/' + commandURLSlug);
	}

	// Handle change on all the input fields
	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
        let errors = props.location.state.errors;

        switch (name) {
			case 'commanddate':
				setCommandDate(event.target.value);
				errors.commandDate = value.length < 5 ? 'Command date required and must be 5 characters long!' : '';
				break;
			case 'commandtitle':
				setCommandTitle(event.target.value);
				errors.commandTitle = value.length < 2 ? 'Command title required and must be 5 characters long!' : '';
				break;
			case 'commandsummary': 
				setCommandSummary(event.target.value);
				errors.commandSummary = value.length < 5 ? 'Command summary required and must be 5 characters long!' : '';
				break;
			case 'commandurlslug': 
				setCommandURLSlug(event.target.value);
				errors.commandURLSlug = value.length < 2 ? 'Command URLSlug required and must be 5 characters long!' : '';
				break;
			case 'commandbody': 
				setCommandBody(event.target.value);
				errors.commandBody = value.length < 5 ? 'Command body required and must be 5 characters long!' : '';
				break;
			default:
				break;
        }

		props.location.state.errors = errors;
	}

	// Validate form before storing to db
	const validateForm = (errors) => {
		let valid = true;
		Object.values(errors).forEach(
			(val) => val.length > 0 && (valid = false)
		);
		return valid;
	}

	// On publish btn clicked
	const handlePublish = (event) => {
		event.preventDefault();
		if (validateForm(props.location.state.errors)) {
			console.info('Valid Form.');
			writeCommand(true, false);
		} else {
			console.error('Invalid Form.');
		}
	}

	// On review btn clicked
	const handleReview = (event) => {
		event.preventDefault();
		if (validateForm(props.location.state.errors)) {
			console.info('Valid Form.');
			writeCommand(false, true);
		} else {
			console.error('Invalid Form.');
		}
	}

	// On save btn clicked
    const handleSave = (event) => {
        event.preventDefault();
        if (validateForm(props.location.state.errors)) {
			console.info('Valid Form.');
			writeCommand(false, false);
		} else {
			console.error('Invalid Form.');
		}
	}

	if (props.dataLoaded && !isAuthenticated)
		return <Redirect to="/error" />;

	return (
		<React.Fragment>
		{!(dataLoaded && props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{pageTitle}</title>
				<NavbarWidget page={page} user={user} />
				<div className="container-fluid mt-5">
					<div className="row">
						<div className="col-sm-12">
							<div className="my-3 p-3 bg-white rounded shadow-sm">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item">Linux Command</li>
									<li className="breadcrumb-item active">Create</li>
								</ol>
								<div className="row">
									<div className="col-sm-6">
										<div className="card-body">
											<h5 className="mb-3">Complete Article Fields</h5>
				                    		<h6 className="card-subtitle mb-4 text-muted pb-2 border-bottom border-gray">Preview of article shown on right or below. Remember to save your articles.</h6>
				                    		
											{!(isAuthenticated && user.user.privileges === "super-admin") ? 
				                    			<h6 className="mb-4">Complete the command form and submit it for review to be published.</h6> 
				                    			: ""
				                    		}
					                    	<div className="form-group">
					                    		<form className="form-inside-input" noValidate>

													<input type="text" name="commanddate" className="form-control" placeholder="Date" value={commandDate} onChange={handleChange} />
													{(props.location.state.errors.commandDate.length > 0) ?
														<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.commandDate}</small>
														: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
													}

													<input type="text" name="commandtitle" className="form-control" placeholder="Command Title" value={commandTitle} onChange={handleChange} />
													{(props.location.state.errors.commandTitle.length > 0) ?
														<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.commandTitle}</small>
														: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
													}

													<input type="text" name="commandsummary" className="form-control mb-2" placeholder="Command Summary" value={commandSummary} onChange={handleChange} />
													{(props.location.state.errors.commandSummary.length > 0) ?
														<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.commandSummary}</small>
														: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
													}

													<input type="text" name="commandurlslug" className="form-control mb-2" placeholder="Command URL Slug" value={commandURLSlug} onChange={handleChange} />
													{(props.location.state.errors.commandURLSlug.length > 0) ?
														<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.commandURLSlug}</small>
														: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
													}

													<textarea name="commandbody" className="code form-control mb-2" id="commandArea" placeholder="Command HTML Body" value={commandBody} onChange={handleChange} rows="15"></textarea>
													{(props.location.state.errors.commandBody.length > 0) ?
														<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.commandBody}</small>
														: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
													}

													{!(isAuthenticated && user.user.privileges === "super-admin") ? 
														<p>
															<button type="button" className="btn btn-primary btn-default mr-1 mb-3" onClick={handleReview}>Submit for Review</button>
															<button type="button" className="btn btn-dark btn-default mr-1 mb-3" onClick={handleSave}>Save</button>
														</p>
														: 
														<p>
															<button type="button" className="btn btn-success btn-default mr-1 mb-3" onClick={handlePublish}>{(isEditCommandPage) ? "Update" : "Publish"}</button>
															<button type="button" className="btn btn-dark btn-default mr-1 mb-3" onClick={handleSave}>Save</button>
														</p>
													}
						                        </form>
					                        </div>
										</div>
									</div>

									<div className="col-sm-6">
										<div className="card-body">
											<h5 className="mb-3">Preview</h5>
			                    			<h6 className="card-subtitle mb-4 text-muted pb-2 border-bottom border-gray">This is how the others will see your article.</h6>

			                    			<h5 className="card-title mt-2">{commandTitle}</h5>
									        <h6 className="card-subtitle mb-2 text-muted">{commandSummary}</h6>

									        <div className="card-text my-3">
										        <small className="text-muted">Published by {(isEditCommandPage) ? command.username : user.user.displayName} on {convertDate(commandDate.split("T")[0])} </small>
										        <br></br>
										    </div>

										    <div className="card-text my-4">
									            {ReactHtmlParser(utf8.decode(commandBody))}
									        </div>
										</div>
									</div>	
								</div>
							</div>
						</div>
					</div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default CreateCommandsPage;