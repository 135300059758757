import React from 'react';
import { Link } from 'react-router-dom';

const RecommendedListWidget = ({ recommendedArticles }) => (
    <div className="my-3 p-3 bg-white rounded shadow-sm">
		<h4 className="display-5 border-bottom border-gray pb-2 mb-0 text-secondary">Suggestions</h4>
		{!(recommendedArticles) ? '' : recommendedArticles.map((article, key) => (
			<ul className="list-group list-group-flush" key={key}>
				<Link to={`/${article.primarycategory[1]}/${article.urlslug}`}>
					<li className="list-group-item">{article.title}</li>
				</Link>
			</ul>
		))}
	</div>
);

export default RecommendedListWidget;