import React from 'react';
import { Navbar } from 'react-bootstrap';

const FooterWidget = () => (
    <Navbar sticky="bottom" >
		<p className="copyright text-white">
		Copyright © Linux.Tips 2020.<br></br>
		Linux is a registered trademark of Linus Torvalds.<br></br>
		This site is not affiliated with Linus Torvalds in any way.
		</p>
	</Navbar>
);

export default FooterWidget;