import React 		from 'react';
import { Link } 	from    "react-router-dom";

const CategoryWidget = ({ categoryList }) => (
    <div className="my-3 p-3 bg-white rounded shadow-sm">
        <h4 className="display-5 border-bottom border-gray pb-2 mb-0 text-secondary">Tags</h4>
        <div className="mt-2">
            {!(categoryList) ? '' : categoryList.map((category, key) => (
                <Link className="badge badge-primary ml-2 mr-1 mt-1 mb-1" to={`/categories/${category.url}`} key={key}>
	                #{category.title}
	            </Link>
            ))}
        </div>
    </div>
);

export default CategoryWidget;