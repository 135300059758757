import React 				from 	'react';
import { Link }             from    "react-router-dom";
import NavbarWidget 		from 	'./widgets/NavbarWidget';
import FooterWidget 		from 	'./widgets/FooterWidget';
import ServicesWidget 		from 	'./widgets/ServicesWidget';
import 								'./Common.css';

const AboutPage = (props) => {
	const page 				= "About";
	const pageTitle 		= "About | Linux.Tips";
	const user 				= props.user;

	return (
		<React.Fragment>
		{!(props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{pageTitle}</title>
				<NavbarWidget page={page} user={user} />
				<div className="container-fluid mt-5">
			        <div className="row">
			            <div className="col-sm-8">
			            	<div className="my-3 p-3 bg-white rounded shadow-sm">
			            		<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item active">About</li>
								</ol>
		                    	<div className="card-body">
									<div className="jumbotron jumbotron-fluid pt-3 pb-1 rounded">
										<div className="container">
											<h3 className="display-5">Welcome!</h3>
											<p className="lead">Be cool and join our community to contribute by writing about your Linux projects, Linux commands. Share your knowledge, learn from others, and grow the community!</p>
										</div>
									</div>

									<div className="jumbotron jumbotron-fluid pt-3 pb-1 rounded mt-3">
										<div className="container">
											<h3 className="display-5">Our Mission</h3>
											<p className="lead">To share our knowledge about Linux with the Global community! Read about anything Linux, software, hardware related.</p>
									    <p>Follow us on Twitter, Facebook, Github. We appreciate all the support.</p>
										</div>
									</div>

									<div className="jumbotron jumbotron-fluid pt-3 pb-1 rounded mt-3">
										<div className="container">
											<h3 className="display-5">How you can contribute?</h3>
											<p className="lead">
												Become a member. Write about your project to share with the community.
											</p>
										</div>
									</div>
								</div>
		                    </div>
			            </div>
			            <div className="col-sm-4">
							<ServicesWidget />
						</div>
			        </div>
				</div>

				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default AboutPage;