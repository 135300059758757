import React, { useState, useEffect } 	from 'react';
import { Link } 						from 'react-router-dom';
import NavbarWidget 					from './widgets/NavbarWidget';
import ArticlesList 					from './widgets/ArticlesList';
import ServicesWidget 					from './widgets/ServicesWidget';
import CategoryWidget 					from './widgets/CategoryWidget';
import RecentCommentsWidget 			from './widgets/RecentCommentsWidget';
import CommandsWidget 					from './widgets/CommandsWidget';
import FooterWidget 					from './widgets/FooterWidget';
import RecommendedListWidget 			from './widgets/RecommendedListWidget';
import CategoryNavbarWidget 			from './widgets/CategoryNavbarWidget';
import CommandNavbarWidget 				from './widgets/CommandNavbarWidget';
import './Common.css'

const HomePage = (props) => {
	const page 		= "Home";
	const pageTitle = "Linux Tips, Tricks and Tutorials - Linux.Tips";
	const user 		= props.user;
	const pageNum 	= (props.match.params.number) ? parseInt(props.match.params.number) : 1;

	// If invalid page num, redirect to error page
	if (isNaN(pageNum)) window.location.replace('/error');

	// Number of articles displayed per page is 10
	const articlePerPage = 10;
	const startIdx 	= (pageNum  - 1) * articlePerPage;

	// Hooks
	const [pagination, 			setPagination] 			= useState('');
	const [articlesList, 		setArticlesList] 		= useState('');
	const [recommendedArticles, setRecommendedArticles] = useState('');
	const [dataLoaded, 			setDataLoaded] 			= useState(false);
	const [commandsList, 		setCommandsList] 		= useState(false);
	const [recentCommentsList, 	setRecentCommentsList] 	= useState(false);
	const [categoryList, 		setCategoryList] 		= useState('');

	useEffect(() => {
		// getRecentCommentsData for widget
		const getRecentCommentsData = async () => {
			const result = await fetch('/comment/recent');
			const commentDocuments = await result.json();
			if (commentDocuments) setRecentCommentsList(commentDocuments);
			else window.location.replace('/');
			setDataLoaded(true);
		}
		
		// getCommandsData for widget
    	const getCommandsData = async () => {
			const result = await fetch('/command/allPublished');
			const commandDocuments = await result.json();
			if (commandDocuments) setCommandsList(commandDocuments);
			else window.location.replace('/');
			getRecentCommentsData();
		}

		const getRecommendedArticles = async () => {
			const response = await fetch(`/article/getRecommendedArticles/`);
			const articles = await response.json();
			if (articles) setRecommendedArticles(articles);
			else window.location.replace('/error');
			getCommandsData();
		}

		const getCategoryData = async () => {
			const response = await fetch('/category/list');
			const categoryDocuments = await response.json();
			if (categoryDocuments) setCategoryList(categoryDocuments);
			else window.location.replace('/error');
			getRecommendedArticles();
		}

		const getArticles = async (articleCount) => {
			const response = await fetch(`/article/getAllPublishedArticles/${pageNum-1}`);
			const articles = await response.json();
			if (articles) {
				// determine pagination if outofbound page, redirect to error
				if (startIdx > articleCount) window.location.replace('/error');
				let nPages = Math.ceil(articleCount/articlePerPage);
				let pagination = [];
				for (var i = 1; i <= nPages; i++) pagination.push(i);
				setPagination(pagination);
				setArticlesList(articles);
				getCategoryData();
			} else {
				window.location.replace('/error');
			}
		}

		const getTotalArticleCount = async () => {
			const response = await fetch(`/article/getAllPublishedArticlesCount/`);
			const articleCount = await response.json();
			if (articleCount) {
				getArticles(articleCount);
			} else {
				window.location.replace('/error');
			}
		}

		if (props.dataLoaded) getTotalArticleCount();

		// scroll to top after page change
		window.scrollTo(0, 0);
	}, [props, startIdx, pageNum]);

	return (
		<React.Fragment>
		{!(dataLoaded && props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{pageTitle}</title>
				<NavbarWidget page={page} user={user} />
				<CategoryNavbarWidget categoryList={categoryList} />
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-8">
							{(pageNum > 1) ? "" : 
								<div className="jumbotron jumbotron-fluid mb-0 pt-3 pb-1 rounded mt-3">
									<div className="container">
										<h3 className="display-5">Built for linux enthusiasts!</h3>
										<p className="lead">Be cool and join our community to contribute by writing about your Linux projects, or teach how to use some Linux commands. Share your knowledge, learn from others, and grow the community!</p>
										<p className="lead"> <a className="btn btn-primary btn-sm" href="/about" role="button">Learn more</a> </p>
									</div>
								</div>
							}

							<div className="my-3 pt-0 p-3 bg-white rounded shadow-sm">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item active">Linux Tips, Tricks and Tutorials</li>
								</ol>
								<ArticlesList articlesList={articlesList} />
							</div>
							<nav aria-label="Page navigation example">
								<ul className="pagination justify-content-center">
									{(pageNum > 1) ? 
										<li className="page-item">
											<Link to={`/page/${(pageNum-1).toString()}`}>
							                    <em className="page-link">Previous</em>
							                </Link>
										</li> 
										:
										<li className="page-item disabled">
											<em className="page-link">Previous</em>
										</li>
									}
									{pagination.map((value, index) => (
										<li className={(value === pageNum) ? "page-item active" : "page-item"} key={value}>
											<Link key={value} className="page-link" to={`/page/${value.toString()}`}>
							                    {value}
							                </Link>
										</li>
									))}
									{(pageNum === pagination.length) ? 
										<li className="page-item disabled">
											<em className="page-link">Next</em>
										</li> 
										:
										<li className="page-item">
											<Link to={`/page/${(pageNum+1).toString()}`}>
							                    <em className="page-link">Next</em>
							                </Link>
										</li>
									}
								</ul>
							</nav>
						</div>
						<div className="col-sm-4">
							<ServicesWidget />
							<CommandsWidget commandsList={commandsList} />
							<RecommendedListWidget recommendedArticles={recommendedArticles} />
							<RecentCommentsWidget recentCommentsList={recentCommentsList} />
						</div>
					</div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default HomePage;