import React, { useState, useEffect } 	from 	'react';
import { Link } 						from 	"react-router-dom";
import NavbarWidget 					from 	'./widgets/NavbarWidget';
import ArticlesList 					from 	'./widgets/ArticlesList';
import CategoryWidget 					from 	'./widgets/CategoryWidget';
import ServicesWidget 					from 	'./widgets/ServicesWidget';
import FooterWidget 					from 	'./widgets/FooterWidget';
import CategoryNavbarWidget 			from 	'./widgets/CategoryNavbarWidget';
import 											'./Common.css';

const CategoryPage = (props) => {
	const page 			= "Category";
	const categoryname 	= props.match.params.categoryname;
	const user			= props.user;
	
	const [category,  				setCategory] 				= useState('');
	const [dataLoaded,				setDataLoaded] 				= useState(false);
	const [articlesList, 			setArticlesList] 			= useState('');
	const [categoryList, 			setCategoryList] 			= useState('');

    useEffect(() => {
    	// Get the articles with this Category
		const getArticlesWithCategory = async () => {
			const response = await fetch(`/category/categoryname/${categoryname}`);
			const categoryDocument = await response.json();
			if (categoryDocument) {
				setCategory(categoryDocument);
				var params 	= categoryDocument.articleid.map((key, idx) => [idx.toString() + "=" + key]).join('&');
				const response 	= await fetch('/article/articlesIdList/?' + params);
				const articleDocuments = await response.json();
				if (articleDocuments) setArticlesList(articleDocuments);
				setDataLoaded(true);
			} else {
				window.location.replace('/error');
			}
		}

		const getCategoryData = async () => {
			const response = await fetch('/category/list');
			const categoryDocuments = await response.json();
			if (categoryDocuments) setCategoryList(categoryDocuments);
			else window.location.replace('/error');
			getArticlesWithCategory();
		}

		if (props.dataLoaded) getCategoryData();
	}, [props, categoryname]);

	return (
		<React.Fragment>
		{!(dataLoaded && props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{category.title + " | Linux.Tips"}</title>
				<NavbarWidget page={page} user={user} />
				<CategoryNavbarWidget categoryList={categoryList} />
				<div className="container-fluid">
					<div className="row">
					    <div className="col-sm-8">
					    	<div className="my-3 p-3 bg-white rounded shadow-sm">
						    	<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item">Category</li>
									<li className="breadcrumb-item active">{category.title}</li>
								</ol>
					        	<ArticlesList articlesList={articlesList} />
					        </div>
					    </div>
					    <div className="col-sm-4">
					    	<ServicesWidget />
					    	<CategoryWidget categoryList={categoryList} />
						</div>
					</div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default CategoryPage;