import React 		from 'react';
import { Link } 	from "react-router-dom";
import './NavbarWidget.css'

const CommandNavbarWidget = ({ commandsList, flag }) => (
    <div className="nav-scroller bg-white box-shadow mt-6">
		<nav className="nav nav-underline">
			<Link className="nav-link active" to="/commands/list/">Linux Commands</Link>
			{!(commandsList) ? '' : commandsList.map((command, key) => (
                <Link className="nav-link" to={`/commands/${command.urlslug}`} key={key}>
	                {command.title}
	            </Link>
            ))}
		</nav>
    </div>
);

export default CommandNavbarWidget;