import React, { useState, useEffect } 	from 	'react';
import { Link } 						from 	'react-router-dom';
import FooterWidget 					from 	'./widgets/FooterWidget';
import NavbarWidget 					from 	'./widgets/NavbarWidget';
import 											'./Common.css';

const UserProfilePage = ( props ) => {
	const page 			= "Profile";
	const pageTitle 	= "Profile | Linux.Tips";
	const user 			= props.user;

	// profile info
	const [displayName, 	setdisplayName] 	= useState('');
	const [email, 			setEmail] 			= useState('');
	const [about, 			setAbout] 			= useState('');

	// super-user data
	const [commands,		setCommands] 		= useState('');
	const [articles,		setArticles] 		= useState('');
	const [comments, 		setComments] 		= useState(false);

	// regular-user data
	const [userArticles,	setUserArticles] 	= useState('');
	const [userCommands,	setUserCommands] 	= useState('');
	const [userComments,	setUserComments] 	= useState('');

	const [dataLoaded, 		setDataLoaded] 		= useState(false);

	useEffect(() => {
		// Input validation
		props.location.state = {
			errors: {
				displayName: '',
				about: '',
			}
		};

		const getMyComments = async () => {
			const result = await fetch(`/comment/userid/${user.user._id}`);
			const commentDocs = await result.json();
			console.log(commentDocs);
			if (commentDocs) setUserComments(commentDocs);
			else window.location.replace('/error');
			setDataLoaded(true);
		}

		const getAllComments = async () => {
			const result = await fetch('/comment/all');
			const commentDocs = await result.json();
			if (commentDocs) setComments(commentDocs);
			else window.location.replace('/error');
			setDataLoaded(true);
		}

		const getMyCommands = async () => {
			const result = await fetch(`/command/userid/${user.user._id}`);
			const commandDocs = await result.json();
			if (commandDocs) setUserCommands(commandDocs);
			else window.location.replace('/error');
			getMyComments();
		}

		const getAllCommandsData = async () => {
			const result = await fetch('/command/all');
			const commandDocs = await result.json();
			if (commandDocs) setCommands(commandDocs);
			else window.location.replace('/error');
			getAllComments();
		}

		const getAllArticles = async () => {
			const result = await fetch('/article/all');
			const articleDocs = await result.json();
			if (articleDocs) setArticles(articleDocs);
			else window.location.replace('/error');
			getAllCommandsData();
		}

		const getMyArticles = async () => {
			const result = await fetch(`/article/all/userid/${user.user._id}`);
			const articleDocs = await result.json();
			if (articleDocs) setUserArticles(articleDocs);
			else window.location.replace('/error');
			getMyCommands();
		}

		// Make sure user data loaded
		if (props.dataLoaded) {
			// if user not logged in kick em out
			if (user.user) {
				// Set input values to display
				setdisplayName(user.user.displayName);
				setEmail(user.user.email);
				setAbout(user.user.about);
				if (user.user.privileges === "super-admin") getAllArticles();
				else getMyArticles();
			} else {
				window.location.replace('/error');
			}
		}
	}, [user, props]);

	const updateUserInfo = async () => {
		const response = await fetch('/user/update', {
			method: 	'post',
			body: 		JSON.stringify({ id: user.user._id, displayName: displayName, about: about }),
			headers: 	{ 'Content-Type': 'application/json' }
		});
		await response.json();

		if (user.user.displayName !== displayName) {
			const response = await fetch('/article/updateUsername', {
				method: 	'post',
				body: 		JSON.stringify({ userId : user.user._id, displayName: displayName}),
				headers: 	{ 'Content-Type': 'application/json' }
			});
			await response.json();
		}
	}

	// Input Validation of the write article form
	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		let errors = props.location.state.errors;

		switch (name) {
			case 'displayName': 
				setdisplayName(event.target.value);
				errors.displayName = value.length < 5 ? 'Post title required and must be 5 characters long!' : '';
				break;
			case 'about': 
				setAbout(event.target.value);
				errors.about = value.length < 5 ? 'About must be 5 characters long!' : '';
				break;
			default:
				break;
		}

		props.location.state.errors = errors;
	}

	const validateForm = (errors) => {
		let valid = true;
		Object.values(errors).forEach(
			(val) => val.length > 0 && (valid = false)
		);
		return valid;
	}

	const handleUpdate = (event) => {
		event.preventDefault();
		if (validateForm(props.location.state.errors)) {
			updateUserInfo(true);
		} else {
			console.error('Invalid Form.');
		}
	}

	const publishArticle = async (articleId, title, primarycategory, urlslug, prev, next) => {
		// first article in the list that may have been unpublished
		// or an article that has never been published
		if (prev.length === 0 && next.length === 0) {
			const response = await fetch('/article/getMostRecentPublishedArticle');
			const articleDocument = await response.json();
			if (articleDocument) {
				// current article becomes the most recent article
				const nxt = [articleDocument[0].title, articleDocument[0].primarycategory[1], articleDocument[0].urlslug];
				const prv = [];

				// Update the prev of the next article to the new article
				const response = await fetch('/article/updatePrevofNext', {
					method: 'post',
					body: JSON.stringify({ 
						articleUrlSlug: 			nxt[2],
						articlePrev: 				[title, primarycategory, urlslug],
					}),
					headers: {
						'Content-Type': 'application/json',
					}
				});

				await response.json();

				// publish article with next, prev
				const result = await fetch('/article/setArticlePrevNextPublished/', {
					method: 'post',
					body: JSON.stringify({ id: articleId, prev: prv, next: nxt }),
					headers: {
						'Content-Type': 'application/json',
					}
				});

				await result.json();
			}
		} else {
			// simply publish the article
			const result = await fetch('/article/publish/', {
				method: 'post',
				body: JSON.stringify({ id: articleId }),
				headers: {
					'Content-Type': 'application/json',
				}
			});

			await result.json();
		}

		window.location.replace('/user/profile');
	};

	const unpublishArticle = async (articleId, prev, next) => {
		if (prev === []) {
			// first article is being unpublished
			// update the prev of the next article to []

			let nextArticleUrlSlug = next[2];
			const response = await fetch('/article/updatePrevofNext', {
				method: 'post',
				body: JSON.stringify({ 
					articleUrlSlug: 			nextArticleUrlSlug,
					articlePrev: 				[],
				}),
				headers: {
					'Content-Type': 'application/json',
				}
			});

			await response.json();

		} else if (next === []) {
			// last article is being unpublished
			// update the next of prev of the current article to []

			let prevArticleUrlSlug = prev[2];
			const response = await fetch('/article/updateNextofPrev', {
				method: 'post',
				body: JSON.stringify({ 
					articleUrlSlug: 			prevArticleUrlSlug,
					articleNext: 				[],
				}),
				headers: {
					'Content-Type': 'application/json',
				}
			});

			await response.json();

		} else {
			// some article in the middle

			// set the prev of next to current prev
			let nextArticleUrlSlug = next[2];
			const response = await fetch('/article/updatePrevofNext', {
				method: 'post',
				body: JSON.stringify({ 
					articleUrlSlug: 			nextArticleUrlSlug,
					articlePrev: 				prev,
				}),
				headers: {
					'Content-Type': 'application/json',
				}
			});

			await response.json();

			// set the next of prev to current next
			let prevArticleUrlSlug = prev[2];
			const responseTwo = await fetch('/article/updateNextofPrev', {
				method: 'post',
				body: JSON.stringify({ 
					articleUrlSlug: 			prevArticleUrlSlug,
					articleNext: 				next,
				}),
				headers: {
					'Content-Type': 'application/json',
				}
			});

			await responseTwo.json();
		}

		const response = await fetch('/article/setArticlePrevNextUnpublished', {
			method: 'post',
			body: JSON.stringify({ 
				articleId: 		articleId,
				articlePrev: 	[],
				articleNext: 	[]
			}),
			headers: {
				'Content-Type': 'application/json',
			}
		});

		await response.json();

		const responseTwo = await fetch('/article/unpublish/', {
			method: 'post',
			body: JSON.stringify({ id: articleId }),
			headers: {
				'Content-Type': 'application/json',
			}
		});

		await responseTwo.json();

		window.location.replace('/user/profile');
	};

	const sendArticleForReview = async (articleId) => {
		console.log("setting in review");
		const result = await fetch('/article/inReview/', {
			method: 'post',
			body: JSON.stringify({ id: articleId }),
			headers: {
				'Content-Type': 'application/json',
			}
		});

		await result.json();

		window.location.replace('/user/profile');
	};

	const publishCommand = async (commandId) => {
		const result = await fetch('/command/publish/', {
			method: 'post',
			body: JSON.stringify({ id: commandId }),
			headers: {
				'Content-Type': 'application/json',
			}
		});

		await result.json();
	};

	const unpublishCommand = async (commandId) => {
		const result = await fetch('/command/unpublish/', {
			method: 'post',
			body: JSON.stringify({ id: commandId }),
			headers: {
				'Content-Type': 'application/json',
			}
		});
		await result.json();
	};

	const sendCommandForReview = async (commandId) => {
		const result = await fetch('/command/inReview/', {
			method: 'post',
			body: JSON.stringify({ id: commandId }),
			headers: {
				'Content-Type': 'application/json',
			}
		});
		await result.json();
	};

	const publishComment = async (commentId, commentArticleUrlslug) => {
		// update the publish field to true and inReview field to false
		const response = await fetch('/comment/publish/', {
			method: 'post',
			body: JSON.stringify({ id: commentId }),
			headers: {
				'Content-Type': 'application/json',
			}
		});

		await response.json();

		// increment comment count in Article collection
        const resultTwo = await fetch('/article/incrementCommentCount/', {
            method: 'post',
            body: JSON.stringify({
                articleUrlslug:     commentArticleUrlslug
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });

        await resultTwo.json();

        // redirect to comments page
        window.location.replace('/user/profile');
	}

	const unpublishComment = async (commentId, commentArticleUrlslug) => {
		// update the publish field to false and inreview field to true
		const response = await fetch('/comment/unpublish/', {
			method: 'post',
			body: JSON.stringify({ id: commentId }),
			headers: {
				'Content-Type': 'application/json',
			}
		});

		await response.json();

		// increment comment count in Article collection
        const resultTwo = await fetch('/article/decrementCommentCount/', {
            method: 'post',
            body: JSON.stringify({
                articleUrlslug:     commentArticleUrlslug
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        });

        await resultTwo.json();

        // redirect to comments page
        window.location.replace('/user/profile');
	}

	return (
		<React.Fragment>
		{!(dataLoaded && props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{pageTitle}</title>
				<NavbarWidget page={page} user={user} />
				<div className="container-fluid mt-5">
			        <div className="row">
			            <div className="col-sm-12">
			            	<div className="my-3 p-3 bg-white rounded shadow-sm">
			            		<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item">User</li>
									<li className="breadcrumb-item active">Profile</li>
								</ol>
		                    	<div className="card-body">
			                		<div className="row">
							            <div className="col-sm-5">
							            	<h3 className="text-center mb-3 text-secondary"><span className="badge badge-dark">Update Your Info</span></h3>
					                    	<div className="form-group">
		                                        <label>Display Name</label>
		                                        <input type="name" name="displayName" className="form-control" placeholder="Enter display name" value={displayName} onChange={handleChange} />
		                                        <small id="emailHelp" className="form-text text-muted">This is displayed when you publish articles or comments.</small>
												{(props.location.state.errors.displayName.length > 0) ? 
													<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.displayName}</small> 
													: <small id="titleerror" className="form-text text-primary mb-2">Valid</small> 
												}
					                        </div>
					                        <div className="form-group">
		                                        <label>Email address</label>
		                                        <input type="email" className="form-control" placeholder="Enter email" value={email} disabled />
		                                        <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
		                                    </div>		
											<div className="form-group">
												<label>About</label>
												<textarea className="form-control" name="about" rows="3" value={about} onChange={handleChange}></textarea>
												<small id="emailHelp" className="form-text text-muted">Introduce yourself to the community.</small>
												{(props.location.state.errors.about.length > 0) ? 
													<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.about}</small>
													: <small id="titleerror" className="form-text text-primary mb-2">Valid</small> 
												}
											</div>
											<p>
												<button type="button" className="btn btn-success btn-default mr-1" onClick={handleUpdate}>Update</button>
											</p>

											<h3 className="text-center mb-3 text-secondary"><span className="badge badge-dark">Your Linux Commands</span></h3>

											<div className="table-responsive">
												<table className="table table-bordered">
													<thead>
														<tr>
															<th scope="col">Status</th>
															<th scope="col">Command</th>
														</tr>
													</thead>
													<tbody>
														{(user.user.privileges === "super-admin") ?
															<>
															{!(commands) ? '' : commands.map((command, key) => (
																<tr key={command._id}>
																	<td key={command._id + 1}>
																		{!(command.isPublished) ?
																			<>
																			<p key={command._id+2} className="badge badge-primary mb-2 mr-4">Need to Review</p>
																			<a key={command._id+3} href="/user/profile" onClick={() => publishCommand(command._id)} className="badge badge-success mb-2 mr-4">Publish</a> 
																			</>
																			:
																			<a key={command._id} href="/user/profile" onClick={() => unpublishCommand(command._id)} className="badge badge-warning mb-2 mr-2">Unpublish</a>
																		}
																		<a key={command._id+1} href={'/commands/edit/' + command.urlslug} className="badge badge-info mr-4">Edit</a>
																	</td>
																	<td key={command._id + 2}>
																		<a key={command._id} href={`/commands/${command.urlslug}`}>{command.title}</a>
																	</td>
																</tr>
															))}
															</>
															:
															<>
															{!(userCommands) ? '' : userCommands.map((command, key) => (									
																<tr key={command._id}>
																	<td key={command._id}>
																		<>
																			{(command.userid === user.user._id) ? 
																				<>
																					{command.inReview ? 
																						<p className="badge badge-secondary text-light mr-4">In review</p>
																						:
																						<>
																						{command.isPublished ?
																							<p className="badge badge-success text-light mr-4">Published</p>
																							:
																							<>
																							<a href="/commands" onClick={() => sendCommandForReview(command._id)} className="badge badge-primary text-light mr-4">Submit for review</a>
																							<a href={'/commands/edit/' + command.urlslug} className="badge badge-info mr-4">Edit</a>
																							</>
																						}
																						</>
																					}
																				</>
																				:
																				""
																			}
																		</>
																	</td>
																	<td>{command.title}</td>
																</tr>
															))}
															</>
														}
													</tbody>
												</table>
											</div>

											<h3 className="text-center mb-3 text-secondary"><span className="badge badge-dark">Your Comments</span></h3>

											<div className="table-responsive">
												<table className="table table-bordered">
													<thead>
														<tr>
															{(user.user.privileges === "super-admin") ?
																<>
																<th scope="col">Status</th>
																<th scope="col">User Name</th>
																<th scope="col">Article Title</th>
																<th scope="col">Comment</th>
																</>
																:
																<>
																<th scope="col">Status</th>
																<th scope="col">Article Title</th>
																<th scope="col">Comment</th>	
																</>
															}
														</tr>
													</thead>
													<tbody>
														{(user.user.privileges === "super-admin") ?
															<>
															{!(comments) ? '' : comments.map((comment, key) => (
																<tr key={comment._id}>
																	<td key={comment._id + 1}>
																		{comment.inReview ?
																			<>
																			<p className="badge badge-primary mb-2 mr-4">Need to Review</p>
																			<p key={comment._id} onClick={() => publishComment(comment._id, comment.articleUrlslug)} className="badge badge-success mb-2 mr-4">Publish</p> 
																			</>
																			:
																			<p key={comment._id} onClick={() => unpublishComment(comment._id, comment.articleUrlslug)} className="badge badge-warning mb-2 mr-2">Unpublish</p> 
																		}
																	</td>
																	<td key={comment._id + 2}>
																		{comment.username}
																	</td>
																	<td key={comment._id + 3}>
																		{comment.articleTitle}
																	</td>
																	<td key={comment._id + 4}>
																		{comment.comment}
																	</td>
																</tr>
															))}
															</>
															:
															<>
															{!(userComments) ? '' : userComments.map((comment, key) => (
																<tr key={comment._id}>
																	<td key={comment._id + 3}>
																		{comment.inReview ? 
																			<p className="badge badge-secondary text-light mr-4">In review</p>
																			:
																			<p className="badge badge-success text-light mr-4">Published</p>
																		}
																	</td>
																	<td key={comment._id + 4}>
																		{comment.articleTitle}
																	</td>
																	<td key={comment._id + 5}>
																		{comment.comment}
																	</td>
																</tr>
															))}
															</>
														}
													</tbody>
												</table>
											</div>
										</div>

										<div className="col-sm-7">
											<h3 className="text-center mb-3 text-secondary"><span className="badge badge-dark">Your Articles</span></h3>

											<div className="table-responsive">
												<table className="table table-bordered">
													<thead>
														<tr>
															<th scope="col">Status</th>
															<th scope="col">Title</th>
														</tr>
													</thead>
													<tbody>
														{(user.user.privileges === "super-admin") ?
															<>
															{!(articles) ? '' : articles.map((article, key) => (
																<tr key={article._id}>
																	<td key={article._id + 1}>
																		{!(article.isPublished) ?
																			<>
																			<p key={article._id+2} className="badge badge-primary mb-2 mr-4">Need to Review</p> 
																			<p key={article._id+3} onClick={() => publishArticle(article._id, article.title, article.primarycategory[1], article.urlslug, article.prevArticle, article.nextArticle)} className="badge badge-success mb-2 mr-4">Publish</p> 
																			</>
																			:
																			<p key={article._id} onClick={() => unpublishArticle(article._id, article.prevArticle, article.nextArticle)} className="badge badge-warning mb-2 mr-2">Unpublish</p> 
																		}
																		<a key={article._id+1} href={'/article/edit/' + article.urlslug} className="badge badge-info mr-4">Edit</a>
																	</td>
																	<td key={article._id + 2}>
																		{!(article.isPublished) ? article.title : <a key={article._id+4} href={`/${article.primarycategory[1]}/${article.urlslug}`}>{article.title}</a>
																		}
																	</td>
																</tr>
															))}
															</>
															:
															<>
															{!(userArticles) ? '' : userArticles.map((article, key) => (
																<tr key={article._id}>
																	<td key={article._id + 1}>
																		{article.inReview ? 
																			<p className="badge badge-secondary text-light mr-4">In review</p>
																			:
																			<>
																			{article.isPublished ? 
																				<p className="badge badge-success text-light mr-4">Published</p>
																				:
																				<>
																				<a href="/articles" onClick={() => sendArticleForReview(article._id)} className="badge badge-primary text-light mr-4">Submit for review</a>
																				<a href={'/article/edit/' + article.urlslug} className="badge badge-info mr-4">Edit</a>
																				</>
																			}
																			</>
																		}
																	</td>
																	<td key={article._id + 2}>
																		{(article.isPublished) ?
																			<p><a key={article._id+4} href={`/${article.primarycategory[1]}/${article.urlslug}`}>{article.title}</a></p>
																			:
																			<p>{article.title}</p>
																		}
																	</td>
																</tr>
															))}
															</>
														}
													</tbody>
												</table>
											</div>
										</div>
		                    		</div>
								</div>
		                    </div>
			            </div>
			        </div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default UserProfilePage;