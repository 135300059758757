import React, { useState, useEffect } 	from 'react';
import { Redirect, Link } 				from "react-router-dom";
import NavbarWidget 					from './widgets/NavbarWidget';
import FooterWidget 					from './widgets/FooterWidget';
import { convertDate }              	from './Util.js';
import ReactHtmlParser              	from 'react-html-parser';

import './Common.css'
import './ArticleCreatePage.css'

const utf8 = require('utf8');

const ArticleCreatePage = ( props ) => {
	const isEditPage 	= (props.match.params.urlslug) ? true : false;
	const page 			= ((isEditPage) ? "Edit" : "Write") + " Article";
	const pageTitle 	= ((isEditPage) ? "Edit" : "Write") + " Article | Linux.Tips";
	const user 		 	= props.user;
	
	// Hooks for user data and data loaded flag
	const [dataLoaded, 			setDataLoaded] 					= useState(false);

	// Hooks for article data
	const [article, 				setArticle] 				= useState('');
	const [articleDate, 			setArticleDate] 			= useState(!(isEditPage) ? new Date().toISOString().split('T')[0] : '');
	const [articleTitle, 			setArticleTitle] 			= useState(!(isEditPage) ? "This is the article title" : '');
	const [articleSummary,  		setArticleSummary]  		= useState(!(isEditPage) ? "This is the article summary" : '');
	const [articleURLSlug,  		setArticleURLSlug]  		= useState(!(isEditPage) ? "this-is-the-url-slug" : '');
	const [articleBody, 			setArticleBody] 			= useState(!(isEditPage) ? "<p>This is a paragraph</p>" : '');
	const [articlePrimaryCategory,	setArticlePrimaryCategory] 	= useState(Array);
	const [articlePrev,				setArticlePrev] 			= useState(Array);
	const [articleNext,				setArticleNext] 			= useState(Array);
	const [articleCategories,		setArticleCategories] 		= useState(Object());

	// Hook for getting all categories from db
	const [categoryList, 			setCategoryList] 			= useState('');
	
	useEffect(() => {
		// Input Validation for article
		props.location.state = {
			errors: {
				articleTitle: 			(isEditPage) ? "" : 'Required',
				articleSummary: 		(isEditPage) ? "" : 'Required',
				articleURLSlug: 		(isEditPage) ? "" : 'Required',
				articleBody: 			(isEditPage) ? "" : 'Required',
				articlePrimaryCategory: (isEditPage) ? "" : 'Required',
				articleDate: 			(isEditPage) ? "" : 'Required'
			}
		};

		// If edit page, query server for the article data
		const getArticleData = async () => {
			const response = await fetch(`/article/urlslug/${props.match.params.urlslug}`);
			const articleDocument = await response.json();
			if (articleDocument) {
				if (user.user.privileges !== "super-admin" && articleDocument.userid !== user.user._id) {
					window.location.replace('/error');
				}
				else {
					setArticle(JSON.parse(JSON.stringify(articleDocument)));
					setArticleTitle(articleDocument.title);
					setArticleSummary(articleDocument.summary);
					setArticleURLSlug(articleDocument.urlslug);
					setArticleBody(articleDocument.body);
					setArticlePrimaryCategory(articleDocument.primarycategory);
					setArticleCategories(articleDocument.categories);
					setArticleDate(articleDocument.date);
					setArticlePrev(articleDocument.prevArticle);
					setArticleNext(articleDocument.nextArticle);
					setDataLoaded(true);
				}
			} else {
				window.location.replace('/error');
			}
		}

		// Query server for all the categories from the db to display in form
		const getCategoryData = async () => {
			const response = await fetch('/category/list');
			const categoryDocuments = await response.json();
			if (categoryDocuments) {
				var categories = {};
				categoryDocuments.forEach(element => categories[element.title] = element.url);
				setCategoryList(categories);
				if (isEditPage) {
					getArticleData();
				}
				else {
					setDataLoaded(true);
				}
			} else {
				window.location.replace('/error');
			}
		}

		// make sure user data is loaded
		if (props.dataLoaded) {
			// if user is logged in
			if (user.user) {
				// get categories data
				getCategoryData();
			} else {
				window.location.replace('/error');	
			}
		}
	}, [user, props, isEditPage]);

	// POST server request for adding the article to db
	const writeArticle = async (isArticlePublished, isArticleInReview) => {
		// Add the primary category to the article categories dict
		if (!(articleCategories[articlePrimaryCategory[0]]))
				articleCategories[articlePrimaryCategory[0]] = articlePrimaryCategory[1];

		// Add/Update Article to/in db
		const requestURL = (isEditPage) ? '/article/update/' : '/article/add/';
		const response = await fetch(requestURL, {
			method: 'post',
			body: JSON.stringify({ 
				articleId: 					(isEditPage) ? article._id 		: "",
  				articleUserId: 				(isEditPage) ? article.userid 	: user.user._id,
				articleUserName: 			(isEditPage) ? article.username : user.user.displayName,
				articleTitle: 				articleTitle,
				articleSummary: 			articleSummary,
				articleURLSlug : 			articleURLSlug,
				articleBody: 				utf8.encode(articleBody),
				articlePrimaryCategory: 	articlePrimaryCategory,
				articleCategories: 			articleCategories,
				isArticlePublished: 		isArticlePublished,
				isArticleInReview: 			isArticleInReview,
				articlePrev: 				articlePrev,
				articleNext: 				articleNext.slice(0,3),
				articleDate: 				articleDate
			}),
			headers: {
				'Content-Type': 'application/json',
			}
		});

		let articleObject = await response.json();

		// If article added successfully, add/remove articleId to/from Categories
		// based on if article is new/edit mode
		if (response.status === 200) {

			// ----- Prev/Next Updates -----
			if (!isEditPage && isArticlePublished && !isArticleInReview) {
				// Update the prev of the next article to the
				// new article
				let updateNextArticleId = articleNext[3];
				const response = await fetch('/article/updatePrevofNext', {
					method: 'post',
					body: JSON.stringify({ 
						articleId: 					updateNextArticleId,
						articlePrev: 				[articleTitle, articlePrimaryCategory[1], articleURLSlug],
					}),
					headers: {
						'Content-Type': 'application/json',
					}
				});

				await response.json();
			}

			// ----- Category updates -----
			var addArticleIdtoCategory = [];

			if (isEditPage) {
				// Update the articles categories
				var removeArticleIdFromCategory = [];

				// Find out which categories were removed from article.categories
				// which are keys from article.categories not in articleCategories
				// and add it to the removeArticleIdFromCategory 
				Object.keys(article.categories).map((k, v) => {
					if (!(articleCategories[k]))
						removeArticleIdFromCategory.push(k);
					return 0;
				});


				// Add it to the all the new categories
				Object.keys(articleCategories).map((k, v) => {
					if (!(article.categories[k]))
						addArticleIdtoCategory.push(k);
					return 0;
				});

				// Server request to remove articleId from Category
				if (removeArticleIdFromCategory.length > 0) {
					const response = await fetch('/category/removeArticleIdsFromCategory', {
						method: 'post',
						body: JSON.stringify({ 
							articleId: 						articleObject._id,
			  				removeArticleIdFromCategory: 	removeArticleIdFromCategory
						}),
						headers: {
							'Content-Type': 'application/json',
						}
					});
			
					await response.json();
					// console.log(response);
				}
			}
			else {
				// Add articleId to all new categories
				Object.keys(articleCategories).map((k, v) => {
					addArticleIdtoCategory.push(k);
					return 0;
				});
			}

			// Server request to add articleId to Category
			const response = await fetch('/category/addArticleIdsToCategory', {
				method: 'post',
				body: JSON.stringify({ 
					articleId: 					articleObject._id,
	  				addArticleIdtoCategory: 	addArticleIdtoCategory
				}),
				headers: {
					'Content-Type': 'application/json',
				}
			});

			await response.json();
			// console.log(response);
		}

		// window.location.replace('/article/' + articleURLSlug);
	}

	// Input Validation of the write article form
	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
        let errors = props.location.state.errors;

        switch (name) {
			case 'articledate':
				setArticleDate(event.target.value);
				errors.articleDate = value.length < 5 ? 'article title required and must be 5 characters long!' : '';
				break;
			case 'articletitle':
				setArticleTitle(event.target.value);
				errors.articleTitle = value.length < 5 ? 'article title required and must be 5 characters long!' : '';
				break;
			case 'articlesummary': 
				setArticleSummary(event.target.value);
				errors.articleSummary = value.length < 5 ? 'article summary required and must be 5 characters long!' : '';
				break;
			case 'articleurlslug': 
				setArticleURLSlug(event.target.value);
				errors.articleURLSlug = value.length < 5 ? 'article URLSlug required and must be 5 characters long!' : '';
				break;
			case 'articlebody': 
				setArticleBody(event.target.value);
				errors.articleBody = value.length < 5 ? 'article body required and must be 5 characters long!' : '';
				break;
			case 'articleprimarycategory': 
				if (!(event.target.value === "Select")) {
					if (articleCategories[articlePrimaryCategory[0]])
						delete articleCategories[articlePrimaryCategory[0]];
					var primarycategory = [event.target.value, categoryList[event.target.value]];
					setArticlePrimaryCategory(primarycategory);
					errors.articlePrimaryCategory = '';
				} else {
					setArticlePrimaryCategory([]);
					errors.articlePrimaryCategory = 'You need to select a primary category!';
				}
				break;
			default:
				break;
        }

		props.location.state.errors = errors;
	}

	const getMostRecentPublishedArticle = async () => {
		const response = await fetch('/article/getMostRecentPublishedArticle');
		const articleDocument = await response.json();
		if (articleDocument) {
			const next = [articleDocument[0].title, articleDocument[0].primarycategory[1], articleDocument[0].urlslug, articleDocument[0]._id];
			const prev = [];
			setArticleNext(next);
			setArticlePrev(prev);
			writeArticle(true, false);
		}
	}

	// On checkbox clicked
    const handleCheckbox = (event) => {
        const name = event.target.name;
		if (articleCategories[name])
			delete articleCategories[name];
		else
			articleCategories[name] = categoryList[name];
	}

	// Validate form before storing to db
	const validateForm = (errors) => {
		let valid = true;
		Object.values(errors).forEach(
			(val) => val.length > 0 && (valid = false)
		);
		return valid;
	}

	// On publish btn clicked
	const handlePublish = (event) => {
		event.preventDefault();
		if (validateForm(props.location.state.errors)) {
			if (!isEditPage) {
				// case: new article setnext = most recent article
				// setprev = []
				getMostRecentPublishedArticle();
			} else {
				// case: updating old article that has been published
				// or unpublished
				writeArticle(true, false);
			}
		} else {
			console.error('Invalid Form.');
		}
	}

	// On save btn clicked
    const handleSave = (event) => {
        event.preventDefault();
        if (validateForm(props.location.state.errors)) {
			writeArticle(false, false);
		} else {
			console.error('Invalid Form.');
		}
	}

	const handleReview = (event) => {
        event.preventDefault();
        if (validateForm(props.location.state.errors)) {
			writeArticle(false, true);
		} else {
			console.error('Invalid Form.');
		}
	}

	// Authenticate user
	var isAuthenticated = user.user ? true : false;

	// If user is not authenticated and data is loaded
	if (dataLoaded && props.dataLoaded && !isAuthenticated)
		return <Redirect to="/error" />;

	return (
		<React.Fragment>
		{!(dataLoaded && props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{pageTitle}</title>
				<NavbarWidget page={page} user={user} />
				<div className="container-fluid mt-5">
			        <div className="row">
			            <div className="col-sm-12">
			            	<div className="my-3 p-3 bg-white rounded shadow-sm">
				            	<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item">Article</li>
									<li className="breadcrumb-item active">{isEditPage ? "Edit" : "Create"}</li>
								</ol>
								<div className="row">
									<div className="col-sm-6">
				                    	<div className="card-body">
				                    		<h5 className="mb-3 border-top pt-2 border-gray">Complete Article Fields</h5>
				                    		<h6 className="card-subtitle mb-4 text-muted pb-2 border-bottom border-gray">Preview of article shown on right or below. Remember to save your article.</h6>
				                    		{!(isAuthenticated && user.user.privileges === "super-admin") ? 
				                    			<h6 className="mb-4">Complete the article form and submit it for review to be published.</h6> 
				                    			: ""
				                    		}
					                    	<div className="form-group">
					                    		<form className="form-inside-input" noValidate>

													<input type="text" name="articledate" className="form-control" placeholder="Date" value={articleDate} onChange={handleChange} />
													{(props.location.state.errors.articleDate.length > 0) ?
														<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.articleDate}</small>
														: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
													}

													<input type="text" name="articletitle" className="form-control" placeholder="Title" value={articleTitle} onChange={handleChange} />
													{(props.location.state.errors.articleTitle.length > 0) ?
														<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.articleTitle}</small>
														: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
													}

													<input type="text" name="articlesummary" className="form-control mb-2" placeholder="Summary" value={articleSummary} onChange={handleChange} />
													{(props.location.state.errors.articleSummary.length > 0) ?
														<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.articleSummary}</small>
														: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
													}

													<input type="text" name="articleurlslug" className="form-control mb-2" placeholder="URL Slug" value={articleURLSlug} onChange={handleChange} />
													{(props.location.state.errors.articleURLSlug.length > 0) ?
														<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.articleURLSlug}</small>
														: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
													}

													<textarea name="articlebody" className="code form-control mb-2" id="articleArea" placeholder="HTML Body" value={articleBody} onChange={handleChange} rows="15"></textarea>
													{(props.location.state.errors.articleBody.length > 0) ?
														<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.articleBody}</small>
														: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
													}

													<label><b>Select Primary Category</b></label>
													<div className="input-group">
														<select className="form-control" name="articleprimarycategory" onChange={handleChange} defaultValue={articlePrimaryCategory[0]}>
															<option>Select</option>
															<>
															{!(categoryList) ? '' : Object.keys(categoryList).map((k, v) => (
																<option key={k}>{k}</option>
															))}
															</>
														</select>
													</div>
													{(props.location.state.errors.articlePrimaryCategory.length > 0) ?
														<small id="titleerror" className="form-text text-danger mb-4">{props.location.state.errors.articlePrimaryCategory}</small>
														: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
													}

													<label><b>Select Secondary Categories</b></label>
													<div className="input-group mb-4">
														<div className="form-check">
															<>
															{!(categoryList) ? '' : Object.keys(categoryList).map((k, v) => (
																(k === articlePrimaryCategory[0] ? "" :
																	<div key={k} className="form-check form-check-inline" >
																		<input className="form-check-input" type="checkbox" name={k} onChange={handleCheckbox} defaultChecked={(articleCategories[k]) ? true : false} />
																		<label className="form-check-label">{k}</label>
																	</div>
																)
															))}
															</>
														</div>
													</div>

													{!(isAuthenticated && user.user.privileges === "super-admin") ? 
														<p>
															<button type="button" className="btn btn-primary btn-default mr-1 mb-3" onClick={handleReview}>Submit for Review</button>
															<button type="button" className="btn btn-dark btn-default mr-1 mb-3" onClick={handleSave}>Save</button>
														</p>
														: 
														<p>
															<button type="button" className="btn btn-success btn-default mr-1 mb-3" onClick={handlePublish}>{(isEditPage) ? "Update" : "Publish"}</button>
															<button type="button" className="btn btn-dark btn-default mr-1 mb-3" onClick={handleReview}>Save</button>
														</p>
													}
						                        </form>
					                        </div>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="card-body">
											<h5 className="mb-3 border-top pt-2 border-gray">Preview</h5>
				                    		<h6 className="card-subtitle mb-4 text-muted pb-2 border-bottom border-gray">This is how the others will see your article.</h6>
											<h5 className="card-title mt-2">{articleTitle}</h5>
									        <h6 className="card-subtitle mb-2 text-muted">{articleSummary}</h6>

									        <div className="card-text my-3">
										        <small className="text-muted">Published by {(isEditPage) ? article.username : user.user.displayName} on {convertDate(articleDate.split("T")[0])} with {(isEditPage) ? article.commentCount : 0} Comment</small>
										        <br></br>
										    </div>

										    <div className="card-text my-4">
									            {ReactHtmlParser(utf8.decode(articleBody))}
									        </div>
								        </div>
									</div>
			                    </div>
		                    </div>
			            </div>
			        </div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default ArticleCreatePage;