import React from 'react';
import { Link } from 'react-router-dom';

const RecentCommentsWidget = ({ recentCommentsList }) => (
    <div className="my-3 p-3 bg-white rounded shadow-sm">
		<h4 className="display-5 border-bottom border-gray pb-2 mb-0 text-secondary">Disqus</h4>
		{!(recentCommentsList) ? '' : recentCommentsList.map((comment, key) => (
			<ul className="list-group list-group-flush" key={key}>
				<li className="list-group-item">
					{comment.comment.substring(0, 35)} {comment.comment.length > 35 ? "..." : ""}
					<br></br>
					<Link to={`${comment.articlePrimaryCategory}/${comment.articleUrlslug}`}>{comment.articleTitle}</Link>
				</li>
			</ul>
		))}
	</div>
);

export default RecentCommentsWidget;