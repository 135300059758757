import React, { useState, useEffect } 	from 	'react';
import { Link } 						from 	"react-router-dom";
import NavbarWidget 					from 	'./widgets/NavbarWidget';
import FooterWidget 					from 	'./widgets/FooterWidget';
import 											'./Common.css';

const CategoryCreatePage = ( props ) => {
	const page 		= "Create Category";
	const pageTitle = "Create Category | Linux.Tips";
	const user		= props.user;

	const [categoryName, 	setCategoryName] 	= useState('');
	const [categoryURL, 	setCategoryURL] 	= useState('');
	const [categoryDesc, 	setCategoryDesc] 	= useState('');

	useEffect(() => {
		// Input Validation
		props.location.state = {
			errors: {
				categoryName: 	'Required',
				categoryURL: 	'Required',
				categoryDesc: 	'Required'
			}
		};

		// make sure user data is loaded
		if (props.dataLoaded) {
			// if user is logged in
			if (user.user) {
				// if user not super admin, get em out of here
				if (user.user.privileges !== "super-admin")
					window.location.replace('/error');
			} else {
				// if user not logged in, get em out of here
				window.location.replace('/error');
			}
		}
	}, [props, user]);

	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
        let errors = props.location.state.errors;

        switch (name) {
			case 'categoryName': 
				setCategoryName(event.target.value);
				errors.categoryName = value.length < 2 ? 'Category name required and must be atleast 1 characters long!' : '';
				break;
			case 'categoryURL': 
				setCategoryURL(event.target.value);
				errors.categoryURL = value.length < 2 ? 'Category URL required and must be atleast 1 characters long!' : '';
				break;
			case 'categoryDesc': 
				setCategoryDesc(event.target.value);
				errors.categoryDesc = value.length < 2 ? 'Category Desc required and must be atleast 1 characters long!' : '';
				break;
			default:
				break;
		}

		props.location.state.errors = errors;
	}

	// POST for adding the article to the database
	const createCategory = async () => {
		const response = await fetch('/category/create/', {
			method: 'post',
			body: JSON.stringify({ 
				categoryName: 	categoryName, 
				categoryDesc: 	categoryDesc, 
				categoryURL: 	categoryURL
			}),
			headers: {
				'Content-Type': 'application/json',
			}
		});

		await response.json();
		console.log(response);
	}

	const validateForm = (errors) => {
		let valid = true;
		Object.values(errors).forEach(
			(val) => val.length > 0 && (valid = false)
		);
		return valid;
	}

	const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm(props.location.state.errors)) {
			console.info('Valid Form.');
			createCategory();
		} else {
			console.error('Invalid Form.');
		}
	}

	return (
		<React.Fragment>
		{!(props.dataLoaded) ? "" :
			<React.Fragment>
				<title>{pageTitle}</title>
				<NavbarWidget page={page} user={user} />
				<div className="container-fluid mt-6">
					<div className="row">
						<div className="col-sm-12">
							<div className="my-3 p-3 bg-white rounded shadow-sm">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
									<li className="breadcrumb-item">Category</li>
									<li className="breadcrumb-item active">Create</li>
								</ol>
								<div className="card-body">
			                    	<div className="form-group">
			                    		<form className="form-inside-input" noValidate>

											<input type="text" name="categoryName" className="form-control" placeholder="Category Name" value={categoryName} onChange={handleChange} />
											{(props.location.state.errors.categoryName.length > 0) ?
												<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.categoryName}</small>
												: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
											}

											<input type="text" name="categoryURL" className="form-control" placeholder="Category URL" value={categoryURL} onChange={handleChange} />
											{(props.location.state.errors.categoryURL.length > 0) ?
												<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.categoryURL}</small>
												: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
											}

											<input type="text" name="categoryDesc" className="form-control" placeholder="Category Description" value={categoryDesc} onChange={handleChange} />
											{(props.location.state.errors.categoryDesc.length > 0) ?
												<small id="titleerror" className="form-text text-danger mb-2">{props.location.state.errors.categoryDesc}</small>
												: <small id="titleerror" className="form-text text-primary mb-2">Valid</small>
											}

											<button type="button" className="btn btn-success btn-default mr-1 mb-3" onClick={handleSubmit}>Submit</button>

										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<FooterWidget />
			</React.Fragment>
		}
		</React.Fragment>
	);
};

export default CategoryCreatePage;