import React, { useState, useEffect }       from 'react';
import { Router, Route }                    from 'react-router-dom';
import { createBrowserHistory }             from 'history';
import HomePage                             from './pages/HomePage'
import UserPage                             from './pages/UserPage'
import UserProfilePage                      from './pages/UserProfilePage'
import UsersPage                            from './pages/UsersPage'
import ArticlePage                          from './pages/ArticlePage'
import ArticleCreatePage                    from './pages/ArticleCreatePage'
import CommandPage                          from './pages/CommandPage'
import CommandsListPage                     from './pages/CommandsListPage'
import CommandCreatePage                    from './pages/CommandCreatePage'
import CategoryPage                         from './pages/CategoryPage'
import CategoryCreatePage                   from './pages/CategoryCreatePage'
import ContactPage                          from './pages/ContactPage'
import ContactMessagesPage                  from './pages/ContactMessagesPage'
import AboutPage                            from './pages/AboutPage'
import ErrorPage                            from './pages/ErrorPage'
import UnknownUrlPage                       from './pages/UnknownUrlPage'

// Listen to location changes to apply some specific actions in response, included in router
const history = createBrowserHistory();

// Main entry point of the app
function App() {

    // Hook for user
    const [user,            setUser]        = useState('');
    const [dataLoaded,      setDataLoaded]  = useState(false);

    useEffect(() => {
        // Query server for user information
        const getUserData = async () => {
            const response = await fetch('/user/auth/isAuthenticated');
            const userInfo = await response.json();
            setUser(userInfo);
            setDataLoaded(true);
        }

        // Get user information
        getUserData();
    }, []);

    return (
        <Router history={history}>
            <Route exact path="/"                           render={(props) => <HomePage            {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/about"                      render={(props) => <AboutPage           {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/page/:number"               render={(props) => <HomePage            {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/article/create"             render={(props) => <ArticleCreatePage   {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route path="/article/edit/:urlslug"            render={(props) => <ArticleCreatePage   {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/category/create"            render={(props) => <CategoryCreatePage  {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/user/profile"               render={(props) => <UserProfilePage     {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route path="/user/id/:userid"                  render={(props) => <UserPage            {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/users/"                     render={(props) => <UsersPage           {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route path="/categories/:categoryname"         render={(props) => <CategoryPage        {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/contact"                    render={(props) => <ContactPage         {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/messages"                   render={(props) => <ContactMessagesPage {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/commands/list/"             render={(props) => <CommandsListPage    {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/commands/create/"           render={(props) => <CommandCreatePage   {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/commands/edit/:urlslug"     render={(props) => <CommandCreatePage   {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route path="/commands/:urlslug"                render={(props) => <CommandPage         {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route path="/:categoryname/:urlslug"           render={(props) => <ArticlePage         {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/error"                      render={(props) => <ErrorPage           {...props} user={user} dataLoaded={dataLoaded} />}  />
            <Route exact path="/*"                          render={(props) => <UnknownUrlPage      {...props} user={user} dataLoaded={dataLoaded} />}  />
        </Router>
    );
}

export default App;
